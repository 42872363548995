<template>
<div v-if="choice" @click="dropOpen=(disabled)?false:!dropOpen" class="drop-container" :id="'drop-container'+choice.destCoinSymbol">
    <div :class="{dropdown:true,disabled:disabled}">
        <div class="field">
            <div class="coin-title">
                <img class='coin-icon' :src="require('../../../public/coins/'+choice.destCoinSymbol+'.png')" />
                {{choice.destCoinSymbol }}
            </div>

            <inline-svg v-if="cryptos.length>1" :class="{icon:true,'drop-open':dropOpen}" :src="require('../../assets/icons/arrowdown.svg')" />
        </div>
        <div class="items" :style="{height:(dropOpen)?filterItems.length*36+'px':'0px'}">
            <div class="field" v-for="(crypto,index) in filterItems" :key="index" @click="choose(crypto)">
                <div class="coin-title">
                    <img class='coin-icon' :src="require('../../../public/coins/'+crypto.destCoinSymbol+'.png')" />
                    {{crypto.destCoinSymbol}}

                </div>
            </div>
        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'DropDown',

    props: ['cryptos', 'disabled'],
    computed: {
        filterItems() {
            return this.cryptos.filter(crypto => {
                if (crypto.destCoinSymbol != this.choice.destCoinSymbol) {
                    return crypto
                }
            })

        },
    },
    created() {
        document.addEventListener('click', (e) => {
            if (!e.target.closest('#drop-container' + this.choice.destCoinSymbol)) {
                this.dropOpen = false
            }
        })
    },
    watch: {
        items() {
            if (!this.choice)
                this.choice = this.cryptos[0]
        }
    },
    data() {
        return {
            dropOpen: false,
            choice: this.cryptos[0],

        }
    },

    methods: {

        choose(item) {
            this.choice = item
            this.$emit('chooseCrypto', this.choice)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.dropdown {
    position: absolute;
    width: 100%;
    padding: 0px 12px;
    background: #060D35;
    border-radius: 4px;
    color: #BDBDBD;
    z-index: 1;
}

.coin-title {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.drop-open {
    transform: rotate(180deg);
}

.items {
    height: 0px;
    transition: height 0.8s ease;
    cursor: pointer;
    max-height: 140px;
    overflow-y: scroll;
}

.drop-container {
    cursor: pointer;
    position: relative;
    max-height: 48px;
    height: 48px;
    flex-grow: 1;
    flex-basis: 80px;

}

.field {
    height: 48px;
    font-size: clamp(14px, 1.5vw, 16px);
    display: flex;
    color: #FCFCFC;
    align-items: center;
    justify-content: space-between;
    column-gap: 6px;
}

.disabled {
    background: #13142A;
}

.icon {
    fill: #FCFCFC;
    cursor: pointer;
    width: 13px;
}

.coin-icon {
    width: 32px;
}

// input{
//     padding: 0px!important;
//     background: var(--dropdown-bg);
//     height:100%;
//     width: 100%;
//     color: var(--dropdown-text);
//     font-size: clamp(13px,1.4vw,14px);
// }
</style>
