<template>
<div class="footer">
    <div class="body">
        <div class="description">
            <div class="site-icon">
                <img src='../../assets/icons/alpha.svg' />
                <span class="site-title">ALPHA Exchange</span>
            </div>
            <div class="details">
                <div class="detail">
                    <div class="detail-label">{{language.address}}</div>
                    <div class="detail-value">{{info.address}}</div>
                </div>
                <div class="detail">
                    <div class="detail-label">{{language.phone}}</div>
                    <div class="detail-value">{{info.number}}</div>
                </div>
                <div class="detail">
                    <div class="detail-label">{{language.mail}}</div>
                    <div class="detail-value">{{info.email}}</div>
                </div>
            </div>
        </div>
        <div class="links">
            <div class="links-group">
                <div class="grp-title">{{language.quickLinks.title}}</div>
                <div class="link" v-for="(link,index) in language.quickLinks.links" :key="index">
                    {{link.label}}
                </div>
            </div>
            <div class="links-group">
                <div class="grp-title">{{language.helpLinks.title}}</div>
                <div class="link" v-for="(link,index) in language.helpLinks.links" :key="index">
                    {{link.label}}
                </div>
            </div>
        </div>

    </div>
    <div class="media-icons">
        <div v-for="(icon,index) in icons" :key="index" class="media-icon">
            <img :src="require('../../assets/icons/'+icon.path+'.svg')" />
            <span>{{icon.label}}</span>
        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'Footer',
    methods: {
        async getInfo() {
            const res = await this.$axios.get('/exchange-info')
            this.info = res.baseDTO || {
                address: '',
                appAndroid: '',
                appIOS: '',
                email: '',
                instagram: '',
                linkedin: '',
                number: '',
                telegram: '',
                twitter: ''
            }
        },

    },

    data() {
        return {
            info: {},
            language: this.$languageDict().footer,
            icons: [{
                    label: 'Whatsapp',
                    path: 'whatsapp'
                },
                {
                    label: 'Twitter',
                    path: 'twitter'
                },
                {
                    label: 'Instagram',
                    path: 'instagram'
                },
                {
                    label: 'Telegram',
                    path: 'telegram'
                }
            ]

        }
    },
    mounted() {
        this.getInfo()
    }

}
</script>

<style scoped>
.links {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    flex-grow: 1;
    column-gap: 50px;
    max-width: 382px;
}

.body {
    display: flex;
    justify-content: space-between;
    max-width: 1094px;
    flex-wrap: wrap;
    row-gap: 40px;
    column-gap: 70px;
    width: 100%;

}

.footer {
    padding: 20px clamp(35px, 4vw, 50px) 25px clamp(30px, 9vw, 120px);
    background: url('../../assets/images/footer-background.png');
    background-repeat: no-repeat;
   
    background-size: cover;
}

.media-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 512px;
    flex-wrap: wrap;
    column-gap: 25px;
    row-gap: 25px;
    width: 100%;
    margin-top: clamp(35px, 4.2vw, 50px);

}

.description {
    display: flex;
    flex-direction: column;
    row-gap: clamp(32px, 3.8vw, 48px);
}

.detail-label {
    color: #E0E0E0;
}

.detail-value {
    color: #FCFCFC;
}

.media-icon {
    display: flex;
    color: #F2F2F2;
    font-size: clamp(12px, 1.3vw, 14px);
    column-gap: 12px;
}

.detail {
    font-size: clamp(14px, 1.6vw, 18px);
    display: flex;
    column-gap: 12px;
}

.site-icon {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.site-title {
    color: #FFFFFF;
    font-size: clamp(18px, 2.2vw, 24px);

}

.details {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.grp-title {
    font-weight: bold;
    color: #FFFFFF;
    white-space: nowrap;

}

.link {
    color: #BDBDBD;
    white-space: nowrap;
    
}

.links-group {
    font-size: clamp(14px, 1.6vw, 18px);
    display: flex;
    flex-direction: column;
    row-gap: clamp(18px, 2.2vw, 26px);
}
</style>
