<template>
<div class="trade-view">
    <VueTradingView v-if="chartOptions" :key="cryptoSymbol" class="chart" :options="chartOptions" />

</div>
</template>

<script>
import VueTradingView from 'vue-trading-view/src/vue-trading-view';
export default {
    name: 'TradeView',
    components: {
        VueTradingView,
    },
    props: {
        cryptoSymbol: {
            type: String
        }
    },
    data() {
        return {
            chartOptions: {
                width: "100%",
                height: "100%",
                "symbol": `${this.cryptoSymbol=='USDT'?("BINANCEUS:USDTUSD"):("BINANCE:"+this.cryptoSymbol+"USDT")}`,
                "interval": "D",
                "timezone": "Asia/Tehran",
                "theme": "dark",
                "style": "1",
                "locale": "en_IR",
                "toolbar_bg": "#f1f3f6",
                "enable_publishing": false,
                "allow_symbol_change": false,
                "hide_top_toolbar": true,
                "hide_legend": false,
                "save_image": false,
            }

        }
    }
}
</script>

<style scoped>

</style>
