<template>
<div class="section">
    <div class="section-title">{{lang.sectionTitle}}</div>
    <div class="text">{{lang.text}}</div>
    <div class="method-switch">
        <div v-for="(option,index) in options" :key="index" class="option" :class="{'active-option':option==twoStep}" @click="showModal(option)">
            <div class="radio" :class="{'active-radio':option==twoStep}">
                <div v-if="option==twoStep" class="active-circle" />
            </div>
            <div for="email">{{lang[option]}}</div>
        </div>
    </div>
    <div class="verify-box" v-if="modalType">
        <div class="text">
            {{lang[modalType+'_Text']}}
        </div>
        <div class="qr-code" v-if="modalType === 'GOOGLE_AUTHENTICATOR'">
            <img :src="'data:image/png;base64,'+qrCode" alt="">
        </div>
        <div class="text" v-if="modalType === 'GOOGLE_AUTHENTICATOR'">{{lang.barcodeText}}</div>
        <VerifyCodeInput @complete="submit()" class="code-input" @change="e=>otp.code=e" :num="6" />
        <button class="verify-btn" :class="{disabled:otp.code.length!==6}"  :disabled="otp.code.length!==6" v-if="countDown>0" @click="submit()">{{lang.verifyBtn}}</button>
        <button class="verify-btn"  v-if="countDown==0" @click="send()">{{lang.resendCodeBtn}}</button>

    

    </div>

</div>
</template>

<script>
export default {
    name: 'TwoFA',
    data() {
        return {
            lang: this.$languageDict().settings.twoFA,
            twoStep: this.state.towStepAuthenticationType,
            qrCode: '',
            qrLink: '',
            email: this.state.userInfo.email,
            mobile: '',
            completedText: '',
            choosed: '',
            modalType: '',
            options: [
                "EMAIL",
                "SMS",
                "GOOGLE_AUTHENTICATOR"
            ],
            otp: {
                code: ''
            },
            countDown: 120,
            timeOut: ''
        }
    },
    methods: {

        async getTwoStepAuth() {
            this.twoStep = this.state.userInfo.towStepAuthenticationType
        },
        async showModal(methodType) {
            this.state.loading = true
            const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=${methodType}`).catch(() => {
                this.getTwoStepAuth()
            })
            if (res) {
                this.mobile = res.baseDTO.starTwoStepAuthentication || ''
                this.qrCode = res.baseDTO.file || ''
                this.qrLink = res.baseDTO.qrCodeLink || ''
                this.modalType = methodType

                console.log(res)
            }
            this.countDownTimer()
            this.getTwoStepAuth()
        },
        async submit() {
            if (this.otp.code.toString().length === 6) {
                this.state.loading = true
                const [res, ] = await this.$http.post(`/users/change-otp?twoStepAuthorization=${this.modalType}&code=${this.otp.code}`)
                if (res) {
                    let e = 'شناسایی دو مرحله ای شما به ' + this.lang[this.modalType] + ' تغییر یافت.';
                    await this.$error('', e, 'success')
                    await this.$getuserInfo()
                    this.getTwoStepAuth()
                    this.modalType=null
                }

            }

        },

        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        async send() {
            this.reset()
            this.state.loading = true
            const a = {
                EMAIL: 'ایمیل',
                SMS: 'شماره'
            }
            const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=${this.twostep}`)
            if (res.message === 'Verification Code Sent') {
                this.$error('', 'کد به ' + a[this.twoStep] + ' شما ارسال شد', 'success')
                if (this.countDown === 0)
                    this.countDown = 120
                this.countDownTimer()
            }
        },

    },
    mounted() {
        this.getTwoStepAuth()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.radio {
    background: #DBDBDB;
    border: 2px solid #828282;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.active-circle {
    background: linear-gradient(225deg, #18D5FF -14.29%, #6E12B6 52.07%, #FF00A8 112.52%);
    border: 2px solid #DBDBDB;
    width: 16px;
    height: 16px;
    border-radius: 50%;

}

.qr-code {
    display: flex;
    justify-content: center;
}

.qr-code>img {
    max-width: min(60%, 114px);
}
.active-radio {
    background: linear-gradient(225deg, #18D5FF -14.29%, #6E12B6 52.07%, #FF00A8 112.52%);
    border: initial;

}
.text{
    max-width: 653px;
}
.method-switch {
    display: flex;
    column-gap: 26px;
    row-gap: 18px;
    flex-wrap: wrap;
    margin-top: 34px;
}

.option {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: clamp(15px, 1.6vw, 18px);
    color: #FCFCFC;
    cursor: pointer;
}

.active-option {
    color: #CB07C3;
}

.verify-box {
    padding: 16px;
    padding-top: 4px;
    background: rgba(66, 66, 66, 0.38);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-top:16px;

}

.verify-btn {
    background: linear-gradient(90deg, #1A97BF 2.72%, #721AC9 28.48%, #AB0AE3 62.88%, #C102C5 78.84%, #BB1396 100%);
    border-radius: 8px;
    color: #FCFCFC;
    font-size: clamp(14px, 1.5vw, 16px);
    max-width: 211px;
    width: 100%;
    height: 35px;
    margin: auto;

}
.code-input{
    margin: auto;
    max-width: 222px;
    border: 1px solid;
}
</style>
