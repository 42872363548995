<template>
<div class="auth-page">
    <div class="auth-card">
        <div class="auth-card-title">{{lang.title}}</div>
        <p class="auth-card-text" v-if="verifyType === 'Email'">{{lang.emailText}}</p>
        <p class="auth-card-text" v-else-if="verifyType === 'GoogleAuth'">{{lang.googleText}}</p>
        <p class="auth-card-text" v-else-if="verifyType === 'SMS'">{{lang.phoneText}}</p>
        <form class="auth-form" @submit="send()">
            <VerifyCodeInput @change="e=>onChange(e)" @complete="submit()" :num="6" />
            <span v-if="verifyType !== 'GoogleAuth'" class="countDown">{{Math.floor(countDown/60)}}:{{countDown%60}}</span>
            <button v-if="countDown>0" :class="{disabled:otp.code.length<6 }" :disabled="otp.code.length<6 " type="submit" class="auth-confirm-btn">{{lang.confirmBtn}}</button>
            <button  class="auth-confirm-btn" v-if="countDown==0" @click="send()">{{lang.resendCode}}</button>

        </form>

    </div>

</div>
</template>

<script>
export default {
    name: 'OTPConfirm',
    data() {
        return {
            lang: this.$languageDict().twoStep,
            verifyType: this.$route.params.type,
            verifyTypes: {
                'Email': 'ایمیل',
                'SMS': 'شماره موبایل',
                'GoogleAuth': 'شناسایی گوگل'
            },
            countDown: 120,
            error: '',
            code: '',
            num: 6,
            otp: {
                code: '',
                email: this.state.userInfo.email || this.$cookies.get('username'),
                phoneNumber: this.state.userInfo.mobilenumber
            }
        }
    },
    methods: {
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        async send() {
            this.state.loading = true
            await this.$axios.post(`/users/resend-otp-code?email=${this.$cookies.get('username')}`)

            this.$error('کد جدید به ' + this.verifyTypes[this.verifyType] + ' شما ارسال شد.', '', 'success')
            this.$cookies.set('username', this.state.userInfo.email)
            if (this.countDown === 0) {
                this.countDown = 120
                this.countDownTimer()
            }

        },

        onChange(e) {
            this.otp.code = e
        },
        async submit() {
            if (this.countDown !== 0) {
                this.state.loading = true
                const res = await this.$axios.post('/users/check-otp-code', this.otp)
                if (res.token) {
                    await this.$setToken(res.token)
                    await this.$router.push('/Panel/Profile')
                }
            }

        },

    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
    mounted() {
        if (!this.otp.email) {
            this.$router.push('/login')
        }
        if (this.verifyType !== 'GoogleAuth') {
            this.countDownTimer()
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
