<template>
  <div class="offer"> 
      <div class="header">
          <div class="sec-title">
              {{language.secTitle}}
          </div>
          <div class="description">
                {{language.description}}
          </div>
      </div>
      <div class="advantages">
          <div class="advantage" v-for="(advantage,index) in language.advantages" :key="index">
              <div class="advantage-header">
                  <img class="advantage-icon" src="../../../assets/icons/advantage-security.svg"/>
                  <div class="advantage-title">{{advantage.title}}</div>
              </div>
              <div class="advantage-text">
                  {{advantage.text}}
              </div>
              
              
          </div>
          
      </div>
    </div>
</template>

<script>
export default {
  name: 'OurOffer',
  props:{
      data:Array
  },
  data(){
      return{
          language: this.$languageDict().homepage.ourOffer,
          
      
   
      }
  },
  mounted(){
     
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.advantage-header{
    display: flex;
    column-gap: 24px;
    align-items: center;
}
.advantage{
    display: flex;
    flex-direction: column;
    padding: 16px;
    row-gap: 24px;
    flex-grow: 1;
    max-width: 290px;
    color: #E0E0E0;
    border-radius: 8px;
    background: #2B1867;
}
.advantages{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 16px;
    margin-top: 80px;
    row-gap: 16px;
}
.offer{
    display: flex;
    flex-direction: column;
    margin-top: clamp(60px,8vw,120px);


}
.chart{
    max-height: 40px;
}
.header{
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    color: #F2F2F2;
    flex-wrap: wrap;
    column-gap: 50px;
    row-gap: 15px;
}
.sec-title{
    font-size: clamp(23px,2.7vw,36px);
    white-space: nowrap;
}
.description{
    font-size: clamp(18px,2vw,24px);
    max-width: 800px;
}
</style>
