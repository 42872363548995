<template>
<div class="carousel-section">
    <hooper class="carousel" :infiniteScroll="true" :progress="true" :autoPlay="true" :playSpeed="1500" :rtl="state.lang=='fa'" :settings="hooperSettings">
        <slide v-for="(crypto,index) in cryptos " :key='index'>
            <div class="slide-container">
                <div class="card">
                    <div class="coin-sign" :id="crypto.relatedCoin">
                        <img class="coin-icon" :src="require('../../../../public/coins/'+crypto.relatedCoin+'.png')"/>
                        <div>{{crypto.relatedCoin}}</div>
                    </div>
                    <div class="card-body">
                        <div class="card-title">{{$coin2Snake[crypto.relatedCoin]}}</div>
                        <div class="prices">
                            <div class="price">
                                <span class="price-label">{{lang.sellLabel}}</span>
                                <div>
                                    <span class="price-value">{{$toLocal(crypto.sellPrice,crypto.marketType.includes('TOMAN')?0:2)}}</span>
                                    <span class="price-tag">{{crypto.marketType.includes('TOMAN')?'TOMANs':'USDT'}}</span>
                                </div>
                            </div>
                            <div class="price">
                                <span class="price-label">{{lang.buyLabel}}</span>
                                <div>
                                    <span class="price-value">{{$toLocal(crypto.buyPrice,crypto.marketType.includes('TOMAN')?0:2)}}</span>
                                    <span class="price-tag">{{crypto.marketType.includes('TOMAN')?'TOMANs':'USDT'}}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </slide>
    </hooper>
</div>
</template>

<script>
import {
    Hooper,
    Slide
} from 'hooper';
import 'hooper/dist/hooper.css';
import * as Vibrant from 'node-vibrant'

export default {
    name: 'Dashboard',
    components: {
        Hooper,
        Slide

    },
    // cryptos() {
    //     this.cryptos.forEach(crypto => {
    //         this.setColor(crypto.relatedCoin)
    //     })
    // },
    data() {
        return {
            lang: this.$languageDict().dashboard.cryptoCarousel,
            cryptos: [],
            hooperSettings: {
                itemsToShow: 2,
                centerMode: false,

                breakpoints: {
                    200: {

                        itemsToShow: 1
                    },
                    630: {
                        itemsToShow: 2
                    },
                    800: {

                        itemsToShow: 3,

                    },
                    1200: {

                        itemsToShow: 4,

                    }
                }
            }

        }
    },
    methods: {
        async getPrice() {
            let res = await this.$axios.get('/trades/market-info?', {
                params: {
                    weeklyDataIncluded: false
                }
            }).then(response => res = response.content)
            if (res) {

                this.cryptos = res.filter(crypto => crypto.marketType.includes('TETHER'))

            }
        },
        
        setColor(code) {
            Vibrant.from(require('../../../../public/coins/BTC.png')).getPalette((err, palette) => {
                // console.log(document.getElementById(code))
                // console.log(palette.Vibrant)
                document.getElementById(code).style.background = "rgba("+palette.Vibrant.rgb+",0.3)"
            })
        },
      
    },
    async mounted() {
        this.alive = true
        await this.getPrice()
    }
    
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.coin-icon{
    width: 35px;
}
.coin-sign {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;
    padding: clamp(7px,0.9vw,11px);
    font-size: clamp(13px, 1.3vw, 14px);
    font-weight: bold;
    color: #FFFFFF;
    height: 100%;
    background: linear-gradient(157.77deg, #E27D06 -4.15%, #E9B654 61.34%, #EF8100 105.07%);
}

.card-title {
    font-weight: bold;
    font-size: clamp(14px, 1.5vw, 18px);
    color: #FFFFFF;
}

.price-label {
    color: #BDBDBD;
    font-size: clamp(12px, 1.3vw, 14px);

}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 10px;
    flex-grow: 1;
}

.price-value {
    color: #FFFFFF;
    font-size: clamp(13px, 1.4vw, 16px);
    font-weight: bold;

}

.price {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.prices {
    display: flex;
    flex-direction: column;
    row-gap: 11px;
}

.price-tag {
    color: #BDBDBD;
    font-size: clamp(13px, 1.4vw, 16px);
    margin-left: 5px;
}

.carousel-section {
    display: flex;
    justify-content: center;
    padding: 12px;
    width: 100%;
    background: linear-gradient(169.98deg, rgba(111, 111, 116, 0.34) 0%, rgba(67, 70, 141, 0.32) 52.32%, rgba(60, 60, 68, 0.32) 92.49%);
}

.carousel {
    height: 120px;
    width: 100%;

}

.slide-container {
    padding: 0px 4px;
    height: 100%;
}

.card {
    height: 100%;
    display: flex;
    column-gap: clamp(8px,1vw,12px);
    background: #0A1239;
    border-radius: 4px;
    padding: 8px;
    max-width: 282px;
}
</style>
