<template>
<div class="dashboard">
    <CryptoCarousel/>
    <div class="middle-section">
        <div class="details-history-section">
            <AccountDetails/>
            <Tables/>
        </div>
        <WalletSection/>
    </div>


</div>
</template>

<script>
import CryptoCarousel from './CryptoCarousel'
import AccountDetails from './AccountDetails'
import WalletSection from './WalletSection'
import Tables from './Tables'

export default {
    name: 'Dashboard',
    components:{
        CryptoCarousel,
        AccountDetails,
        WalletSection,
        Tables,
    },
    data() {
        return {
            lang: this.$languageDict().dashboard,

        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.dashboard{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding-bottom: 90px;

}
.middle-section{
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 8px;
}
.details-history-section{
   flex-grow: 3;
    flex-basis: 400px;
}
</style>
