<template>
<div class="sidebar-container" >
    <div class="sidebar" @click.self="sideOpen=!sideOpen" :class="{'open-sidebar':sideOpen}" >
    <div class="site-title">
        <img class="site-icon"  @click.self="$router.push('/')" src="../../assets/icons/alpha.svg" />
        <div :class="{resp:!sideOpen}">{{lang.siteTitle}}</div>
    </div>
    <div class="level-container">
        <div class="user-level">
            <inline-svg class="level-curve tr-curve" :class="{'active-level':userLevels[state.userInfo.userAccountLevel]>=1}" :src="require('../../assets/icons/level-curved-slice.svg')" />
            <inline-svg class="level-curve br-curve" :class="{'active-level':userLevels[state.userInfo.userAccountLevel]>=2}" :src="require('../../assets/icons/level-curved-slice.svg')" />
            <inline-svg class="level-curve bl-curve" :class="{'active-level':userLevels[state.userInfo.userAccountLevel]>=3}" :src="require('../../assets/icons/level-curved-slice.svg')" />
            <inline-svg class="level-curve tl-curve" :class="{'active-level':userLevels[state.userInfo.userAccountLevel]==4}" :src="require('../../assets/icons/level-curved-slice.svg')" />
            <div class="wolf-shadow" />
            <div class="wolf-container">
                <img src="../../assets/icons/wolf-level.svg" />
            </div>
        </div>
    </div>
    <div class="links">
        <div v-for="(link,index) in links" :key="index" :class="{'link-container':!link.sublinks,
        'drop-container':link.sublinks,'active-container':isActive(link)}"
        @mouseover="hoverIndex = index"
        @mouseleave="hoverIndex = null" >
            <!-- link -->
            <div class="link "  :class="{'centered-link':!sideOpen,
            'active-link':$route.name == link.name && !link.sublinks}" @click="!link.sublinks?$router.push('/Panel/'+link.name):''">
                <div>
                    <inline-svg class="icon" :src="require('../../assets/icons/'+link.name+'.svg')" />
                    <div :class="{resp:!sideOpen}">{{lang.links[link.name]}}</div>
                </div>
                <inline-svg v-if="link.sublinks" :class="{'arrow-open':isActive(link)}"  :src="require('../../assets/icons/arrowdown.svg')" class="arrow-icon resp" />
            </div>

            <!-- sublinks -->
            <div class="sublinks" 
            :style="{height:(hoverIndex==index||isActive(link)) && link.sublinks?44*link.sublinks.length+'px':'0px',opacity:hoverIndex==index||link.sublinks||isActive(link)?'1':'0'}">
                <div v-for="(sublink,i) in link.sublinks"  :key="i+index" class="link" 
                :class="{'active-link':$route.path.includes(link.name+'/'+sublink.name)}"
                @click="$router.push('/Panel/'+link.name+'/'+sublink.name)">
                    <div  >
                        <inline-svg class="icon" :src="require('../../assets/icons/'+sublink.name+'.svg')" />
                        <div :class="{resp:!sideOpen}">{{lang.links[sublink.name]}}</div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
</div>
</template>

<script>
export default {
    name: 'PanelSidebar',
    props: {},
   
    data() {
        return {
            userLevels: {
                'BRONZE': 1,
                'SILVER': 2,
                'GOLD': 3,
                'PLATINUM': 4,
                'SUPERVISOR': 5,
            },
            hoverIndex:null,
            sideOpen: false,
            level: 1,
            lang: this.$languageDict().panelSidebar,
            links: [{
                    name: "Dashboard"
                },
                {
                    name: "Profile"
                },
                {
                    name: "Exchange"
                },
                {
                    name: "Wallets"
                },
                {
                    name: "History",
                    sublinks: [{
                            name: "Trades"
                        },
                        {
                            name: "Exchange"
                        },
                        {
                            name: "Transactions"
                        },
                        {name:"RialDepsoit"},
                        {name:"RialWithdraw"}
                    ]
                },
                {
                    name: "Rewards"
                },
                {
                    name: "Settings"
                },
                {
                    name: "Support"
                },
            ]
        }
    },
    methods:{
           isActive(link) {
            return (this.$route.name == link.name && !link.sublinks) || (this.$route.path.includes(link.name) && link.sublinks)
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.link>div {
    display: flex;
    align-items: center;
    column-gap: 11px;

}

.site-title {
    flex-wrap: wrap;
    background: linear-gradient(90.76deg, #3A79F3 0.19%, #6B57E4 37.26%, #ED19FF 62.48%, #FF19CC 102.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: bold;
    justify-content: center;
    column-gap: 4px;
    font-size: clamp(15px, 1.6vw, 18px);
    display: flex;
    white-space: nowrap;
    row-gap: 10px;
    align-items: center;

}

.level-curve {
    position: absolute;
    z-index: 2;
    fill: #031738;
    width: 49%;
    height: 49%;

}

.active-level {
    fill: #EF07A0;
}

.tr-curve {
    top: 0;
    right: 0;
    transform: rotate(0deg);
}

.br-curve {
    bottom: 0;
    right: 0;
    transform: rotate(90deg);
}

.bl-curve {
    bottom: 0;
    left: 0;
    transform: rotate(180deg);
}

.tl-curve {
    top: 0;
    left: 0;
    transform: rotate(270deg);
}

.level-container {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    margin-top: 34px;

}

.user-level {
    /* width: 5vw; */
    /* height: 5vw; */
    width: max(50%,4vw);
    aspect-ratio: 1/1;
    min-width: 40px;
    /* min-height: 40px; */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

}

.wolf-shadow {
    position: absolute;
    width: 120%;
    height: 120%;
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%, rgba(109, 141, 255, 0.4) 0%, rgba(212, 157, 255, 0.4) 100%);
    filter: blur(15px);
}

.wolf-container {
    margin-right: 0.5px;
    z-index: 3;
    width: 83%;
    height: 83%;
    background: radial-gradient(50% 50% at 50% 50%, #2F4FC2 0%, #4F1A78 100%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wolf-container>img {
    max-width: 80%;
}

.site-icon {
    max-width: min(40px, 100%);
    cursor: pointer;
}

.icon {
    fill: #BDBDBD;
}

.sidebar-container{
    position: sticky;
    bottom: 0px;
    top: 0px;
    width: 20vw;
    min-width: 44px;
    transition: width 1s;
    max-width: 200px;
    background: #031143;
    height: 100vh;
    z-index: 100;


}

.sidebar {
    z-index: 100;
    display: flex;
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    padding: 24px clamp(4px, 0.8vw, 10px);
    transition: width 1s;
    background: #031143;
    height: 100%;

    overflow-y: auto;


}

.links {
    margin-top: 55px;
    padding-right: clamp(0px, 0.3vw, 10px);
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    row-gap: 5px;

}

.arrow-icon {
    fill: #BDBDBD;
    cursor: pointer;
    width: 13px;
    transform: rotate(-90deg);
}
.drop-container:hover .arrow-icon {
    transform: initial;
}
.arrow-open{
    transform: initial;
}
.active-link .icon{
    fill: #CB07C3!important;
}

.sublinks {
    display: flex;
    flex-direction: column;
    transition: height 1s,opacity 2s;
    overflow: hidden;
}


.link {
    font-size: clamp(15px, 1.6vw, 18px);
    display: flex;
    cursor: pointer;
    column-gap: 15px;
    align-items: center;
    justify-content: space-between;
    padding: 0px clamp(4px, 0.4vw, 7px) 0px clamp(4px, 0.4vw, 12px);
    min-height:44px;

}

.link-container {
    border-radius: 4px;
    color: #BDBDBD;
}

.drop-container {
    border-radius: 4px;
    overflow: hidden;
    color: #BDBDBD;
    /* height: 0px; */
}
.drop-container:hover{
    background: linear-gradient(135deg, #1C2028 0%, #293652 67.16%, #10254E 100%); 
}
.link-container:hover{
    background: linear-gradient(135deg, #1C2028 0%, #293652 67.16%, #10254E 100%); 
}
.link:hover{
    color: #FCFCFC;
}
.link:hover .icon{
    fill:#FCFCFC ;
}.link:hover .arrow-icon{
    fill:#FCFCFC ;
}


.active-container {
    background: linear-gradient(135deg, #142960 0%, #132C64 67.16%, #0E398E 100%)!important;
    box-shadow: -2px 2px 4px rgba(39, 86, 72, 0.2), 2px -2px 4px rgba(39, 86, 72, 0.2), -2px -2px 4px rgba(39, 86, 72, 0.2), 2px 2px 5px rgba(51, 70, 60, 0.2), inset 1px 1px 2px rgba(73, 78, 89, 0.3), inset -1px -1px 2px rgba(45, 48, 55, 0.5);

}

.active-link {
    color: #CB07C3!important;
}


@media(max-width:720px) {
    .resp {
        display: none;
    }

    .sidebar-container {
        min-width: 44px;
        width: 5.1vw;
        /* max-width: 46px; */
    }

    .open-sidebar {
        width: 160px;
        max-width: 160px;

    }

    .centered-link {
        justify-content: center;
    }
}
</style>
