<template>
<div class="auth-page">
    <div class="auth-card">
        <div class="auth-card-title">{{lang.title}}</div>
        <form class="auth-form" @submit.prevent="send()">
            <LabelInput @input="value=>{data.email=value;emailError=''}" :errorText="emailError" :label="lang.emailLabel" />
            <LabelInput @input="value=>{data.password=value;passwordError=''}" type='password' :label="lang.passwordLabel" />
            <LabelInput @input="value=>{data.referralCode=value;}" :label="lang.refrralLabel" />
            <div class="terms">
                <span class="checkbox" :class="{'checkbox-active':agree}" @click="agree=!agree">
                    <span class="checkmark" v-if="agree"></span>
                </span>
                <span class="auth-card-text">
                    {{lang.agreePhrase}}
                    <a @click.prevent="$router.push('/Terms')" href="/Terms" class="auth-link">{{lang.termsLink}}</a>
                </span>
            </div>
            <button :class="{disabled:disable  }" :disabled="disable" type="submit" class="auth-confirm-btn">{{lang.confirmBtn}}</button>
        </form>
        <div class="auth-card-links">
            <div>
                <span class="auth-card-text">{{lang.suggestPhrase}}</span>&nbsp;<a @click.prevent="$router.push('/Login')" href="/Login" class="auth-link">{{lang.suggestLink}}</a>
            </div>
        </div>
        <button class="auth-google-btn">
            <span>{{lang.googleBtnLabel}}</span>
            <img src='../../assets/icons/google.svg' />
        </button>
    </div>

</div>
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
            lang: this.$languageDict().signup,
            recaptcha: false,
            agree: false,
            data: {
                email: '',
                password: '',
                referralCode: '',
                deviceType: '',
                osType: '',
                browser: '',
            },
            emailError: '',
            passwordError: ''

        }
    },
    computed: {
        disable() {
            return !this.data.email || !this.data.password || this.emailError.length > 0 || this.passwordError.length > 0 || !this.agree
        },
        readOnly() {
            return localStorage.referralCode || this.$route.query.ref
        }
    },
    methods: {

        googleSso() {
            window.location.href = this.$google_sso
        },
        async send() {
            try {

                await this.checkEmail()
                await this.check2Pass()

                this.state.loading = true
                const res = await this.$axios.post('/users/register', this.data)
                if (res.message === 'user created successfully , verification email sent') {
                    if (res.baseDTO.towStepAuthenticationType === 'EMAIL') {
                        this.$cookies.set('username', this.data.email)
                        this.state.userInfo.email = this.data.email
                        // await this.$router.push('OTPConfirm')
                        await this.$router.push('/Panel/Profile')

                    }
                } else if (res.token) {
                    this.$setToken(res.token, this.state.userInfo.remember)
                    await this.$router.push('/Panel/Dashboard')
                }
            } catch (e) {
                console.log(e)
            }
        },
        check2Pass() {
            if (!this.$validPass(this.data.password) || this.data.password.length < 8) {
                this.passwordError = 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد'
                this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                return Promise.reject('wrong pass : ' + this.data.password)
            } else {
                this.passwordError = ''
            }
        },
        checkEmail() {
            if (!this.$validEmail(this.data.email) || !this.data.email) {
                this.emailError = 'ایمیل صحیح وارد نمایید'
                this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                return Promise.reject('repeat failed: ' + this.data.email)
            } else {
                this.emailError = ''
            }
        },

        getData() {
            this.data.osType = this.$detectOs()
            this.data.browser = this.$detectBrowser() || 'CHROME'
            this.data.deviceType = this.$detectDevice()
        },

    },
    mounted() {
        this.getData()
        if (this.$route.query.ref || localStorage.referralCode) {
            localStorage.referralCode = this.$route.query.ref || localStorage.referralCode
            this.data.referralCode = this.$route.query.ref || localStorage.referralCode
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.terms {
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.checkbox {
    cursor: pointer;
    background: #DBDBDB;
    border: 2px solid #828282;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox-active {
    background: linear-gradient(224.33deg, #6ADBFF 7.52%, #A781EE 46.64%, #FF00B8 102.53%);
    border: initial
}

.checkmark {
    border: 2px solid #DBDBDB;
    background: linear-gradient(225deg, #18D5FF -14.29%, #6E12B6 52.07%, #FF00A8 112.52%);
    border-radius: 2px;
    width: 16px;
    height: 16px;
}
</style>
