<template>
<div>

    <div class="history-table-title">
        {{lang.rialWithdrawHistory}}
    </div>
      <div class="filters">
<RangeCalendar @input="value=>{startDate=value[0];endDate=value[1]}" />
    </div>

    <div class="history-table-container">
        <perfect-scrollbar @ps-y-reach-end="getWithdrawHistory()" ref="scroll">
            <table class="history-table">
                <tr class="history-table-header">
                    <td class="resp-1">{{lang.tableHeaders.time}}</td>
                    <td>{{lang.tableHeaders.volume}} <span class="tag" v-if="coin">({{$coins[coin].code}})</span></td>
                    <td class="resp-2" >{{lang.tableHeaders.iban}}</td>
                    <td class="resp-1">{{lang.tableHeaders.trackNumber}}</td>
                    <td class="resp-2">{{lang.tableHeaders.status}}</td>
                </tr>
                <tr class="history-table-row" v-for="(transaction,index) in withdrawList" :key="index">
                    <td class="resp-1">{{$G2J(transaction.createdAtDateTime)}}</td>
                    <td dir='ltr' >{{$toLocal(transaction.amount,$decimal[transaction.relatedCoin])}}
                      </td>
                    <td class="resp-2">{{transaction.destinationWalletAddress || '-'}}</td>
                    <td class="resp-1">{{transaction.hashCode || '-'}}</td>
                    <td class="resp-2">
                        <div :style="{color:statusColor(transaction.transactionStatus)}"  class="status">
                            {{lang.statusLabels[transaction.transactionStatus]}}</div>
                    </td>
                </tr>
            </table>
        </perfect-scrollbar>
    </div>

</div>
</template>

<script>
import RangeCalendar from '../../Tools/RangeCalendar'
import {
    statusLabel,
    statusColor

} from "@/library/reuseableFunction";

export default {
    name: 'Logins',
    components: {
        RangeCalendar
    },
    props: {

    },
  
    computed: {

      
        query() {
            let str = '?size=20&relatedCoin=TOMAN'
             if(this.startDate || this. endDate)
                str+='&startDate='+this.startDate+'T00:00:00'+'&endDate='+this.endDate+'T23:59:59'
       
            return str
        },

        statusLabel,
        statusColor
    },
    methods: {
        async getWithdrawHistory() {
            if (this.withdrawPage <= this.withdrawLastPage) { //for scroll loading
                this.withdrawPage++ //for scroll loading
                this.loading = true
                let params = {
                    page: this.withdrawPage,
                }

                const [res, ] = await this.$http.get(`wallets/withdrawal-requests?${this.query}`, {
                    params
                })
                if (res) {
                    this.withdrawList = [...this.withdrawList, ...res.content]
                    this.withdrawLastPage = res.totalPages

                }
                this.loading = false

            }

        },

    },
    mounted() {
        this.getWithdrawHistory()
    },
    data() {
        return {
             endDate:'',
            startDate:'',
            lang: this.$languageDict().history,
            withdrawList: [],
            loading: false,
            withdrawPage: 0,
            withdrawLastPage: 1,
            dict: {
                'WITHDRAW': 'برداشت',
            },
            coin: ''

        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.left-filter {
    flex-grow: 1;
    max-width: 280px;
}

.mar {
    width: 600px;
}

@media(max-width:1200px) {

    .resp-1 {
        display: none;
    }
}

@media(max-width:900px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width:600px) {
    .resp-3 {
        display: none;
    }
}
</style>
