<template>
<div class="code-input-container">
    <CodeInput dir="ltr" class="input" :fields="num" :fieldHeight="28" :fieldWidth="33" :loading="false"
     @change="e=>$emit('change',e)" @complete="e=>$emit('complete',e)" ref="code-input" />
</div>
</template>

<script>
import CodeInput from "./CodeInput";
export default {
    name: 'VerifyCodeInput',
    props: {
        num: Number
    },
    data() {
        return {
            code: []
        }
    },
    components: {
        CodeInput

    },
    methods: {
        // setPlaceholder() {
        //     for (let i = 0; i < this.num; i++) {
        //         this.$refs['code-input'].$refs['input_' + i][0].placeholder = 0
        //     }
        // },
        // reset() {
        //     this.otp.code = ''
        //     for (let i = 0; i < this.num; i++) {
        //         this.$refs['code-input'].values[i] = ''
        //         this.$refs['code-input'].$refs['input_' + i][0].value = ''
        //     }
        //     this.$refs['code-input'].$refs['input_0'][0].focus()
        // },

    },
    // mounted() {

    //     this.setPlaceholder()
    // },

}
</script>

<style scoped>
::v-deep input[type="tel"]::placeholder {}

::v-deep .input {
    width: 100% !important;

}

.code-input-container {
    overflow: hidden;
    max-height: 44px;
    border: initial !important;
}

::v-deep .react-code-input {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #060D35;
      padding: 0px 14px;
    column-gap: 8px;
    height: 44px;
    width: 100%;
    border-radius: 4px;
}

::v-deep input {
    border: none !important;
    border-bottom: 2px solid #C4C4C4 !important;
    padding: 0px !important;
    font-family: 'VistaDev', sans-serif !important;
    font-size: clamp(16px, 2vw, 24px);
    border-radius: initial !important;
    width: 100% !important;
    max-width: 40px;
    letter-spacing: -2px;
    text-align: center !important;
    color: white !important;
    background: transparent;
}

/* ::v-deep .react-code-input {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 14px;
    column-gap: 6px;
    height: 44px;
    width: 100% ;
    max-width: 317px;
    background: #060D35;
    border-radius: 4px;
}

::v-deep input {
    size: 1px;
    min-width: 10px !important;
    width: 100%;
    border: none !important;
    border-bottom: 2px solid #BDBDBD !important;
    font-family: 'VistaDev', sans-serif !important;
    font-size: 25px;
    color: white !important;
    background: transparent;
    border-radius: 0px !important;
    letter-spacing: -2px;
    text-align: center !important;
} */

/* 

input[type="number"] {

text-align: center;

appearance: textfield;
      font-size: clamp(18px,1.6vw,19px); 

-webkit-appearance: textfield;
    flex-grow: 1;
    max-width: clamp(30px,3.1vw,33px);
    border-radius: 4px;
    background: #EDF1ED;

}
.code-container{
    display: flex;
    align-items: center;
    width: 100%;
    height: 44px;
    padding: 9px ;
    justify-content: center;
    column-gap: clamp(5px,1.3vw,15px);
   background: #EDF1ED;
border-radius: 6px;
}
.underline{
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background: #BDBDBD;
} */
</style>
