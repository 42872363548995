<template>
<div class="rewards">
    <div class="page-header">
        <div class="page-description">
            <h4>{{lang.headerQuote}}</h4>
            <h1>{{lang.pageTitle}}</h1>
            <p>{{lang.headerDescribe}}</p>
        </div>
        <div class="wolf-reward">
            <img src="../../assets/images/reward-wolf.png" />
        </div>
    </div>
    <div class="referrals">
        <div class="referral-box code-box">
            <div class="box-title">{{lang.codeLabel}}</div>
            <div class="referral-value" @click="copyCode()">
                <img src="../../assets/icons/copy.svg" />
                {{inviteData.referralCode}}
            </div>
        </div>
        <div class="referral-box link-box">
            <div class="link-box-header">
                <div class="box-title">{{lang.linkLabel}}</div>
                <div class="medias-sec">
                    {{lang.mediasLabel}}
                    <img src='../../assets/icons/colory-twiter.svg'/>
                    <img src='../../assets/icons/colory-whatsapp.svg'/>
                    <img src='../../assets/icons/colory-facebook.svg'/>
                    <img src='../../assets/icons/colory-instagram.svg'/>
                    <img src='../../assets/icons/colory-telegram.svg'/>
                </div>
            </div>
            <div class="referral-value" @click="copyLink()">
                <img src="../../assets/icons/copy.svg" />
                {{inviteData.referralLink}}
            </div>

        </div>
    </div>
    <div class="steps">
        <div class="step">
            <img class="step-img" src='../../assets/images/wolf-post.png' />
            <div class="step-text">{{lang.step1}}</div>
        </div>
        <img src='../../assets/images/curved-arrow.png' />
        <div class="step">
            <img class="step-img" src='../../assets/images/wolf-openbag.png' />
            <div class="step-text">{{lang.step2}}</div>
        </div>
        <img class="reverse-curved-arrow" src='../../assets/images/curved-arrow.png' />
        <div class="step">
            <img class="step-img" src='../../assets/images/wolf-moneybag.png' />
            <div class="step-text">{{lang.step3}}</div>
        </div>
    </div>
    <div class="rewards-data-box">
        <div class="box-title">{{lang.rewardsBoxHeader}}</div>
        <div class="reward-details">
            <div class="reward-detail">
                <div class="reward-label">{{lang.friendsNumberLabel}}</div>
                <div class="reward-value">{{$toLocal(inviteData.countInvitees,0)}}</div>
            </div>
            <div class="reward-detail">
                <div class="reward-label">{{lang.totalFeesLabel}}</div>
                <div class="reward-value">{{$toLocal(inviteData.profit,0)}}</div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
    name: 'Rewards',
    props: {},
    data() {
        return {
            lang: this.$languageDict().rewards,
            codeAlertShow: false,
            linkAlertShow: false,
            inviteData: {
                countInvitees: '',
                profit: '',
                referralCode: '',
                referralLink: ''
            }

        }
    },
    methods: {
        copyLink() {
            navigator.clipboard.writeText(this.inviteData.referralLink)
            this.linkAlertShow = true;
            setTimeout(() => {
                this.linkAlertShow = false;
            }, 2000)
        },
        copyCode() {
            navigator.clipboard.writeText(this.inviteData.referralCode)
            this.codeAlertShow = true;
            setTimeout(() => {
                this.codeAlertShow = false;
            }, 2000)
        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/users/invites');
            this.inviteData = res.baseDTO;
            this.inviteData.referralLink = 'Alpha.com/SignUp?refer_code=' + this.inviteData.referralCode
            this.state.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@media(max-width:1085px) {
    .steps {
        flex-direction: column;
    }

}

.reward-label {
    font-size: clamp(15px, 1.6vw, 18px);
}

.reward-value {
    font-size: clamp(18px, 2.1vw, 24px);
}

.rewards-data-box {
    margin-top: clamp(35px, 4.5vw, 56px);
    background: #030920;
    border-radius: 8px;
    padding: clamp(16px, 2vw, 24px);
}

.reward-details {
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;

}

.reward-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
    color: #E0E0E0;
}

.steps {
    margin-top: 26px;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    align-items: center;
    row-gap: 40px;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 158px;
}

.reverse-curved-arrow {
    transform: rotate(180deg);
}

.step-text {
    color: #FFFFFF;
    font-size: clamp(15px, 1.6vw, 18px);
    margin-top: 12px;

}

.step-img {
    margin: auto;
}

.rewards {
    width: 80%;
    margin: auto;
    padding-bottom: 70px;

}

h4 {
    color: #F2994A;
    font-size: clamp(16px, 2vw, 24px);
    font-weight: bold;
}

h1 {
    color: #E0E0E0;
    font-size: clamp(25px, 3vw, 36px);
}

p {
    color: #E0E0E0;
    font-size: clamp(15px, 1.6vw, 18px);
}

.page-header {
    display: flex;
    flex-wrap: wrap;
}

.wolf-reward {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    max-width: 360px;
    flex-basis: 300px;

}

.wolf-reward img {
    width: 100%;
}

.page-description {
    flex-grow: 2;
    flex-basis: 260px;
}

.referrals {
    display: flex;
    column-gap: 24px;
    row-gap: 24px;
    flex-wrap: wrap;
}

.referral-box {
    padding: clamp(16px, 2vw, 24px);
    background: #030920;
    border-radius: 8px;

}

.code-box {
    flex-grow: 1;
}

.link-box {
    flex-grow: 10;
}

.box-title{
    color: #E0E0E0;
    font-weight: bold;
    font-size: clamp(18px, 2.1vw, 24px);
}

.link-box-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.medias-sec {
    display: flex;
    align-items: center;
    column-gap: 18px;
    font-size: clamp(15px, 1.6vw, 18px);
    color: #E0E0E0;
    row-gap: 10px;
    white-space: nowrap;
    flex-wrap: wrap;
}

.referral-value {
    display: flex;
    column-gap: clamp(10px, 1.2vw, 14px);
    align-items: center;
    color: #F2F2F2;
    font-size: clamp(16px, 2vw, 24px);
    margin-top: 24px;
    cursor: pointer;
}

@media(max-width:400px) {
    .rewards {
        width: 90%;
    }
}
</style>
