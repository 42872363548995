<template>
<div class="drop-container" :id="'drop-container'+choice.value" @click="dropOpen=!dropOpen">
    <div class="dropdown">
        <div class="field">
            <!-- <input v-model="choice.label" :disabled="true"/> -->
            <!-- <inline-svg v-if="type=='search'" class="icon" :src="require('../../assets/icons/search.svg')"/> -->
            {{choice.label}}
            <inline-svg :class="{icon:true,'drop-open':dropOpen}" :src="require('../../../assets/icons/arrowdown.svg')" />
        </div>
        <div class="items" :style="{height:(dropOpen)?filterItems.length*36+'px':'0px'}">
            <div class="field" v-for="(item,index) in filterItems" :key="index" @click="choose(item)">
                {{item.label}}
            </div>
        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'DropDown',
    props: {
        placeholder: String,
        type: String,
        items: Array,
        defaultValue: String
    },
    computed: {
        filterItems() {
            return this.items.filter(item => {
                if (item.value != this.choice.value) {
                    return item
                }
            })

        },
    },
    created() {
        document.addEventListener('click', (e) => {
            if (!e.target.closest('#drop-container' + this.choice.value)) {
                this.dropOpen = false
            }
        })
    },
    watch: {
        items() {
            if (!this.choice)
                this.choice = this.items.find(item => item.value == this.defaultValue) || this.items[0]
        }
    },
    data() {
        return {
            dropOpen: false,
            choice: this.items.find(item => item.value == this.defaultValue) || this.items[0],

        }
    },
    methods: {
        // filterItems(){
        //     return this.items.filter(item=>{
        //         if(item.label.includes(this.choice.label) || item.value.includes(this.choice.label)){
        //             return item
        //         }
        //     })

        // }, 
        choose(item) {
            this.choice = item
            this.$emit('choose', item)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.dropdown {
    position: absolute;
    width: 100%;
    padding: 0px 16px;
    background: #18223D;
    border-radius: 4px;
    color: #BDBDBD;
    z-index: 1;

}

.drop-open {
    transform: rotate(180deg);
}

.items {
    height: 0px;
    transition: height 0.8s;
    cursor: pointer;
    max-height: 140px;
    overflow-y: scroll;
}

.drop-container {
    cursor: pointer;
    position: relative;
    max-height: 36px;
    height: 36px;
    flex: 1;
    flex-basis: 80px;
    min-width: 100px;
    max-width: 136px;

}

.field {
    height: 36px;
    font-size: clamp(12px, 1.3vw, 14px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 6px;

}

.icon {
    fill: #FCFCFC;
    cursor: pointer;

}

// input{
//     padding: 0px!important;
//     background: var(--dropdown-bg);
//     height:100%;
//     width: 100%;
//     color: var(--dropdown-text);
//     font-size: clamp(13px,1.4vw,14px);
// }
</style>
