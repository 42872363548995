<template>
<div class="wallet-section">
    <div class="filter">
        <SwitchComponent @change="value=>walletType=value" :items="['P2P','EXCHANGE']" />
    </div>
    <div class="chart">
        <apexchart type='donut' :key='series.length+""+change' :series="series" :options="options"></apexchart>
    </div>
    <div class="coins">
        <div class="coin" v-for="(coin,index) in wallets" :key="index">
            <div class="coin-icon">
                <img :src="require('../../../../public/coins/'+coin[0]+'.png')" />
            </div>
            <div class="coin-body">
                <div class="coin-details">
                    <div class="coin-percent">{{$toLocal((coin[1].wholePriceOfCredit/totalValue)*100,2)}}%</div>
                    <div class="coin-credit">{{$toLocal(coin[1].credit,$decimal[coin[0]])}}&nbsp;{{$coins[coin[0]].code}}</div>
                </div>
                <div class="coin-value">
                    {{$toLocal(coin[1].wholePriceOfCredit,0)}} TOMANs
                </div>
            </div>
        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'WalletSection',
    props: {},
    watch: {
        'walletType': function () {
            this.getWallets()
        },
        wallets() {
            this.chart()
        }
    },

    data() {
        return {
            walletType: "P2P",
            // lang: this.$languageDict().dashboard.cryptoCarousel,
            totalValue: 0,
            wallets: {},
            change: 0,
            series: [0],
            options: {
                tooltip: {
                    enabled: false
                },
                xaxis: {
                    type: "numeric"
                },
                labels: [],

                dataLabels: {
                    enabled: false,
                },
                states: {
                    hover: {
                        filter: {
                            type: 'lighten',
                        }
                    },
                },

                legend: {
                    show: false
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'round',
                    width: 10,
                    colors: ['transparent']
                },
                plotOptions: {

                    pie: {
                        expandOnClick: false,

                        donut: {
                            size: '85%',
                            background: '#0A1239',

                            labels: {
                                show: true,

                                name: {
                                    show: true,
                                    fontSize: 'clamp(12px,1.5vw,18px)',
                                    fontFamily: 'Nimbus',
                                    color: '#F2F2F2',

                                    fontWeight: 600,
                                    offsetY: -17,
                                    formatter: function (val) {
                                        return val.name || val
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: 'clamp(14px,2vw,26px)',
                                    fontFamily: 'Nimbus',
                                    fontWeight: 400,
                                    color: '#FFFFFF',
                                    offsetY: 10,
                                    formatter: function (val) {
                                        return parseFloat(val).toLocaleString()
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: false,
                                    label: 'Estimated Total Value',
                                    fontSize: 'clamp(12px,1.5vw,18px)',
                                    fontFamily: 'Nimbus',
                                    color: '#F2F2F2',
                                    fontWeight: 600,
                                    formatter: function (w) {
                                        w = w.globals.seriesTotals.reduce((a, b) => a + b)
                                        return w.toLocaleString() + '\n TOMANs'
                                    }
                                }
                            }
                        }
                    }
                },
            },

        }
    },
    mounted() {
        this.getWallets()

    },
    methods: {
        chart() {
            let index = 0
            this.wallets.forEach((e) => {
                    this.options.labels[index] = {
                        'name': this.$coins[e[0]].persianName,
                        'value': e[1].credit || 0
                    }
                    this.totalValue += e[1].wholePriceOfCredit

                    this.series[index] = this.$S2N(this.$toLocal(e[1].wholePriceOfCredit, 0)) || this.$S2N(this.$toLocal(e[1].totalBalance, 0)) || 0
                    index++
                })
                ++this.change
            console.log(this.series)
        },
        async getWallets() {
            this.state.loading = true

            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=' + this.walletType)
            if (res) {
                let entireRes = Object.entries(res)
                const sortCoins = this.$walletSortCoins
                entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
                this.wallets = entireRes
                this.chart()

            }
            this.loading = false

        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.chart{
  max-width: 400px;
  margin: auto;
}
.coins {
    display: flex;
    flex-wrap: wrap;
    row-gap: 12px;
    column-gap: 12px;
    margin-top: 24px;
    max-height: 400px;
    overflow-y: auto;
}

.coin {
    background: #0A1239;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    column-gap: 8px;
    flex-grow: 1;
    flex-basis: 220px;

}

.coin-icon {
    width: 40px;
    display: flex;
    align-items: center;
    height: 100%;
}

.coin-icon img {
    width: 100%;
}

.coin-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 8px;
}

.coin-percent {
    font-size: clamp(15px, 1.6vw, 18px);
    color: #FFFFFF;
}

.coin-credit {
    font-size: clamp(12px, 1.3vw, 14px);
    color: #F2F2F2;
}

.coin-value {
    color: #BDBDBD;
    font-size: clamp(11px, 1.1vw, 12px);

}
.coin-body{
  display: flex;
  align-items: flex-end;
  row-gap: 5px;
  column-gap: 6px;
  flex-wrap: wrap;
  justify-content: space-between;
    flex: 1;

}
.wallet-section {
    background: linear-gradient(169.98deg, rgba(111, 111, 116, 0.34) 0%, rgba(67, 70, 141, 0.32) 52.32%, rgba(60, 60, 68, 0.32) 92.49%);
    border-radius: 8px;
    padding: 16px;
    flex-grow: 2;
    flex-basis: 300px;
}

.filter {
    display: flex;
    justify-content: flex-start;

}
</style>
