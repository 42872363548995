<template>
<div class="exchange" v-if="crypto">
    <div class="trade-section">
        <div class="basecoin-filter">
          <SwitchComponent @change="(choice)=>{baseCoin=choice}" :items="['TOMAN','TETHER']" />
        </div>
        <div class="dealType-switch">
            <div :class="{'active sell':dealType=='SELL'}" @click="dealType='SELL'">{{lang.SELL}}</div>
            <div :class="{'active buy':dealType=='BUY'}" @click="dealType='BUY'">{{lang.BUY}}</div>
        </div>
        <CryptoDropdown :cryptos="cryptos" @chooseCrypto="(coin) => {crypto=coin;setCrypto()}" />

        <!-- <DropDown class="base-coin-filter" @choose="coin=>baseCoin=coin" :items="[{label:'تومان',value:'TOMAN'},{label:'تتر',value:'TETHER'}]" />
        <DropDown class="crypto-filter" @choose="coin=>findCoin(coin)" :items="coinsOpts" /> -->
        <div class="limits">
            <div class="exchange-limit">
                {{lang.maxAmount}}&nbsp;
                {{crypto.maxAmountExchange}}
                {{$coins[crypto.destCoinSymbol].code}}
            </div>
            <div class="exchange-limit">
                {{lang.minAmount}}&nbsp;
                {{crypto.minAmountExchange}}
                {{$coins[crypto.destCoinSymbol].code}}
            </div>

        </div>

        <LabelInput :disabled="confirmToggle" :value="0" @input="value=>amount=value" :label="lang.amountLabel" type="number" :unit="$coins[crypto.destCoinSymbol].code" />
        <div class="credit">
            <span class="credit-label">{{lang.avlLabel}}</span>
            <div>
                <span class="credit-value">{{credit}}</span>&nbsp;
                <span class="credit-tag">{{(dealType=='BUY')?baseCoin:$coins[crypto.destCoinSymbol].code}}</span>
            </div>
        </div>

        <LabelInput :unit="baseCoin" :disabled="true" :value="$toLocal(sum,this.baseCoin=='TOMAN'?0:2)" :label="lang.totalLabel" />

        <!-- <div class="currency-net">
            شبکه انتقال ارز
            <DropDown :items="networkOpts" />
        </div> -->
        <div class="exchange-limit">
            {{lang.fee}}&nbsp;
            {{crypto.exchangeFee}}
            {{$coins[crypto.destCoinSymbol].code}}
        </div>
        <div class="wallet-label" v-if="dealType=='BUY'">{{lang.destAddress}}</div>
        <div class="wallet-switch" v-if="dealType=='BUY'">
            <div :class="{'active':wallet=='EXTERNAL'}" @click="wallet='EXTERNAL'"><span>{{lang.extWalletLabel}}</span></div>
            <div :class="{'active':wallet=='LOCAL'}" @click="wallet='LOCAL'"><span>{{lang.locWalletLabel}}</span></div>
        </div>
        <LabelInput v-if="wallet=='EXTERNAL' && dealType=='BUY'" :label="lang.walletAddress" />
        <p class="error">{{errorHandler}}</p>
        <div class="result" v-if="confirmToggle">
            <div>
                <span class="label-tag">{{lang.receiveLabel}}</span>
                <div>
                    <span>{{receive}}</span>
                    <span class="label-tag">{{(dealType=='SELL')?baseCoin:$coins[crypto.destCoinSymbol].code}}</span>
                </div>
            </div>
            <div>
                <span class="label-tag">{{dealType=='BUY'?lang.buyPriceLabel:lang.sellPriceLabel}}&nbsp;{{$coins[crypto.destCoinSymbol].code}} </span>
                <div>
                    <span>{{$toLocal(unitPrice,baseCoin=="TOMAN"?0:2)}}</span>
                    <span class="label-tag">{{baseCoin}}</span>
                </div>
            </div>
            <div>
                <span class="label-tag pay">{{lang.payLabel}}</span>
                <div>
                    <span>{{pay}}</span>
                    <span class="label-tag">{{(dealType=='BUY')?baseCoin:$coins[crypto.destCoinSymbol].code}}</span>
                </div>
            </div>

        </div>

        <button class="btn" :class="{'sell-btn':dealType=='SELL','buy-btn':dealType=='BUY',disabled:disabled}" :disabled="disabled" @click="confirmToggle?sendData():checkModal()">
            {{(confirmToggle?lang.confirmBtn[0]:lang.submitBtn[0])+' '+
            (dealType=='SELL'?lang.SELL:lang.BUY)+' '+
            (confirmToggle?lang.confirmBtn[1]:lang.submitBtn[1])}}
        </button>
    </div>
        <Success v-if="modalType=='success'" @modalToggle="value=>modalType=value" :order="order"/>

</div>
</template>

<script>
// import DropDown from '../Tools/DropDown'
// import TradeConfirm from '../../Modals/TradeConfirm.vue'
import Success from '../Modals/Success'
import CryptoDropdown from '../Tools/CryptoDropdown'
import {
    statusLabel,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'Exchange',
    components: {
        CryptoDropdown,
        // DropDown,
        // TradeConfirm,
        Success
    },
    data() {
        return {
            lang: this.$languageDict().exchange,
            langErrors: this.$languageDict().errors,
            cryptoNetwork: "TRC20",
            baseCoin: 'TOMAN',
            depsoit: '',
            dealType: "BUY",
            walletAddress: '',
            cryptos: [],
            crypto: '',
            wallets: '',
            confirmToggle: false,
            coinsDetails: [],
            net: '',
            amount: 0,
            modalType: '',
            data: {},
            toman: true,
            coinNet: '',
            wallet: 'LOCAL',
            orders: [],
            loading: false,
            order: {},
            networkOpts: [],
            

        }
    },
    watch: {
        baseCoin() {
            this.getCoinsPrice()
        },
        unitPrice() {
            this.order.unitPrice = this.unitPrice

        }
    },
    computed: {

        coinsOpts() {
            return this.cryptos.map(coin => {

                return {
                    label: this.$coins[coin.destCoinSymbol].persianName,
                    value: coin.destCoinSymbol
                }
            })
        },
        receive() {
            return this.$toLocal(this.dealtype == 'BUY' ? this.amount : this.sum, this.baseCoin == 'TOMAN' ? 0 : 2)
        },
        pay() {
            return this.$toLocal(this.dealType == 'BUY' ? this.sum : this.amount, this.baseCoin == 'TOMAN' ? 0 : 2)
        },
        statusLabel,
        tomanBalance() {

            if (this.wallets['TOMAN']) {
                const temp = this.wallets['TOMAN']
                return this.$toLocal(temp.credit, 0)
            }
            return 0
        },
        tetherBalance() {
            if (this.wallets['TETHER']) {
                const temp = this.wallets['TETHER']
                return this.$toLocal(temp.credit, this.$decimal[this.crypto.destCoinSymbol])
            }
            return 0
        },
        disabled() {
            return !this.$S2N(this.amount) ||
                (!this.walletAddress && this.wallet == 'EXTERNAL' && this.dealType == 'BUY') ||
                (this.$S2N(this.dealType == 'BUY' ? this.sum : this.amount) > this.$S2N(this.credit)) ||
                (this.$S2N(this.amount) > this.$S2N(this.crypto.maxAmountExchange)) ||
                (this.$S2N(this.amount) < this.$S2N(this.crypto.minAmountExchange))
        },
        errorHandler() {
            if ((this.$S2N(this.dealType == 'BUY' ? this.sum : this.amount) > this.$S2N(this.credit)))
                return this.langErrors.creditError
            else if ((this.$S2N(this.amount) < this.$S2N(this.crypto.minAmountExchange)))
                return this.langErrors.exchangeMinAmount[0] + this.$coins[this.relatedCoin].code + ' ' + this.crypto.minAmountExchange + this.langErrors.exchangeMinAmount[1]
            else if ((this.$S2N(this.amount) > this.$S2N(this.crypto.maxAmountExchange)))
                return this.langErrors.exchangeMaxAmount[0] + this.$coins[this.relatedCoin].code + ' ' + this.crypto.maxAmountExchange + this.langErrors.exchangeMaxAmount[1]
            else if (!this.amount)
                return this.langErrors.amountError
            else if (!this.walletAddress && this.wallet == 'EXTERNAL' && this.dealType == 'BUY')
                return this.langErrors.walletError
            else
                return ''

        },
        credit() {
            return (this.dealType == 'BUY') ? (this.baseCoin == 'TOMAN' ? this.tomanBalance : this.tetherBalance) :
                this.$toLocal(this.crypto.credit, this.$decimal[this.crypto.destCoinSymbol])
        },
        sum() {
            return this.unitPrice * this.$S2N(this.amount)
        },
        unitPrice() {
            return this.$S2N((this.dealType == 'BUY') ? this.crypto.buyPrice : this.crypto.sellPrice)
        },
        sumExchangeFee() {
            return this.$S2N(this.crypto.exchangeFee) * this.$S2N(this.amount)

        }

    },
    methods: {
        coinTitle(marketType) {
            return this.$coins[marketType.replace('_TOMAN', '')].persianName || this.$coins[marketType.replace('_TETHER', '')].persianName
        },
        findCoin(choiceCoin) {
            this.crypto = this.cryptos.find(coin => coin.destCoinSymbol == choiceCoin);
            this.setCrypto()
        },

        async getCoinsPrice() {
            await this.$axios.get(`coins/exchange-price/${this.baseCoin=='TOMAN'?'usd':'usdt'}`, {
                params: {
                    includeWeeklyChange: false
                }
            }).then(response => this.cryptos = response).then(this.setCrypto)
        },
        async getWallets() {
            this.state.loading = true
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE')
            if (res) {
                this.wallets = res
            }
            this.loading = false
        },
        async getCoinsDetails() {
            const res = await this.$axios.get('/coins')
            this.coinsDetails = res.content
        },

        setCrypto() {
            if (this.crypto)
                this.crypto = this.cryptos.find(coin => coin.destCoinSymbol == this.crypto.destCoinSymbol)
            else {
                this.crypto = this.cryptos[0]
            }
            if (this.crypto && this.wallets && this.coinsDetails) {
                this.crypto.minAmountExchange = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).minAmountExchange
                this.crypto.maxAmountExchange = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).maxAmountExchange
                this.crypto.credit = this.wallets[this.crypto.destCoinSymbol].credit
                this.crypto.nets = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).tokenTypeDetails
                this.net = this.crypto.nets[0].tokenType
                this.networkOpts = this.crypto.nets.map(net => {
                    return {
                        label: net.tokenType,
                        value: net.tokenType
                    }
                })
                this.crypto.exchangeFee = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).exchangeFee
            }

        },
        checkModal() {
            this.order.amount = this.amount
            // this.order.marketType = this.crypto.destCoinSymbol + '_' + (this.baseCoin)
            this.order.coin = this.crypto.destCoinSymbol
            this.order.tokenType = this.net
            // this.order.totalPrice = this.sum
            // this.order.unitPrice = this.unitPrice
            this.order.baseCoin = this.baseCoin
            this.order.tradeType = this.dealType
            this.order.orderType = "EXCHANGE"
            if (this.wallet == 'EXTERNAL' && this.dealType == 'BUY')
                this.order.destinationWallet = this.walletAddress
            this.confirmToggle = true
            this.modalType = this.dealType
        },
        async sendData() {
            this.modalType = ''
            this.temptData = JSON.parse(JSON.stringify(this.order))
            this.temptData.amount = this.$S2N(this.order.amount)
            this.temptData.orderType = this.order.orderType + '_' + this.order.tradeType
            this.temptData.marketType = this.crypto.destCoinSymbol + '_' + (this.baseCoin)

            this.temptData.totalPrice = this.$S2N(this.sum)
            this.temptData.unitPrice = this.$S2N(this.order.unitPrice)
            this.modalType = ''
            if (this.dealType == 'BUY') {

                this.state.loading = true
                await this.$axios.post(
                    '/exchange/buy', this.temptData
                )

                this.modalType = 'success'

            } else {
                delete this.temptData.destinationWallet
                delete this.temptData.tokenType

                this.state.loading = true
                await this.$axios.post(
                    '/exchange/sell', this.temptData
                )
                this.modalType = 'success'

                this.state.loading = false
            }

        },
        async update() {
            let tempCoin
            await this.$axios.get('coins/exchange-price/usd', {
                params: {
                    includeWeeklyChange: false
                }
            }).then(response => tempCoin = response.find(coin => coin.destCoinSymbol == crypto.destCoinSymbol))

            if (tempCoin) {
                this.crypto.buyPrice = tempCoin.buyPrice
                this.crypto.sellPrice = tempCoin.sellPrice

            }

        }
    },
    async mounted() {
        console.log('dsfdsa')
        this.loading = true
        await this.getCoinsDetails()
        await this.getWallets()

        if (this.state.loop) {
            this.loop = new Loop(this.getCoinsPrice, 5000)
            this.loop.start()
        } else {
            this.getCoinsPrice()
        }
        this.loading = false
    },
    beforeDestroy() {
        this.loop.stop()

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.exchange-limit {
    font-size: clamp(13px, 1.5vw, 16px);
    color: #F2F2F2;
    margin-top: 4px;

}

.limits {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 6px;

}
.basecoin-filter{
    display:flex;
    justify-content: flex-start;
    margin-bottom: 12px;
}
.wallet-label {
    font-size: clamp(14px, 1.5vw, 16px);
    margin-top: 24px;
    margin-bottom: 8px;
}

.credit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 4px;
}

.dealType-switch {
    display: flex;
    padding: 5px 8px;
    color: #828282;
    background: #060D35;
    border-radius: 8px;
    font-size: clamp(14px, 1.7vw, 20px);
    margin-bottom: 24px;

    div {
        flex-grow: 1;
        padding: 5px;
        text-align: center;
        cursor: pointer;

    }

    .buy {
        color: #17B471;
    }

    .sell {
        color: #E30037;
    }

    .active {
        font-weight: bold;
        background: #232555;
        border-radius: 4px;
    }
}

.wallet-switch {
    display: flex;
    padding: 5px 8px;
    color: #828282;
    background: #060D35;
    border-radius: 8px;
    font-size: clamp(14px, 1.5vw, 16px);
    margin-bottom: 12px;

    div {
        flex-grow: 1;
        padding: 6px 8px;
        text-align: center;
        cursor: pointer;
    }

    .active {
        font-weight: bold;
        background: #232555;
        border-radius: 4px;
    }

    .active>* {
        background: linear-gradient(224.33deg, #6ADBFF 7.52%, #A781EE 46.64%, #FF00B8 102.53%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
}

.credit-value {
    font-size: clamp(15px, 1.6vw, 18px);
    font-weight: bold;
    text-decoration: underline;
}

.credit-tag {
    font-size: clamp(14px, 1.5vw, 16px);
}

.credit-label {
    font-size: clamp(15px, 1.6vw, 18px);
}

.exchange {
    padding-bottom: 25px;

}

.coin-details-order {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 24px;
    background: var(--otc-section-bg);
    box-shadow: 0px 0px 64px rgba(28, 57, 187, 0.05);
    border-radius: 20px;
    row-gap: 24px;
}

.crypto-filter {
    z-index: 4;
}

.disabled {
    opacity: 0.8;
}

.base-coin-filter {
    z-index: 20 !important;

}

.error {
    color: #DD1B60;
    font-size: clamp(12px, 1.3vw, 14px);
}

.trade-section {
    background: linear-gradient(169.98deg, rgba(111, 111, 116, 0.34) 0%, rgba(67, 70, 141, 0.32) 52.32%, rgba(60, 60, 68, 0.32) 92.49%);
    border-radius: 12px;
    padding: clamp(12px, 1.8vw, 24px);
    display: flex;
    flex-direction: column;
    margin: auto;
    border-radius: 20px;
    max-width: 446px;
    flex-grow: 1;
    color: #F2F2F2;
}

.result {
    display: flex;
    flex-direction: column;
    row-gap: clamp(8px, 1.2vw, 16px);
    font-size: clamp(12px, 1.2vw, 16px);
    background: #030920;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 24px;

    div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 6px;

    }

    .label-tag {
        color: #E0E0E0;
    }

    .value {
        color: #F2F2F2;
    }

    .pay {
        font-weight: bold;
    }
}

.currency-net {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    font-size: clamp(12px, 1.4vw, 14px);
    column-gap: 16px;

}

.sell-btn {
    background: #82112B;

}

.btn {
    border-radius: 8px;
    padding: 12px 12px;
    color: #FCFCFC;
    font-size: clamp(14px, 1.5vw, 16px);
}

.buy-btn {
    background: #02A830;

}
</style>
