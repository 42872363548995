<template>
<div class="panel-page">
    <PanelSidebar />
    <div class="panel" :class="{'exchange-bg':$route.name=='Exchange'}">
        <PanelNavbar />
        <div class="panel-comp">
            <Notifications v-if="$route.name=='Notifications'" />
            <Rewards v-if="$route.name=='Rewards'" />
            <Settings v-if="$route.name=='Settings'" />
            <Profile v-if="$route.name=='Profile'" />
            <Dashboard v-if="$route.name=='Dashboard'" />
            <Support v-if="$route.name=='Support'" />
            <Wallets v-if="$route.name=='Wallets'" />
            <Exchange v-if="$route.name=='Exchange'" />
            <Trade v-if="$route.name=='Trade'" />
            <History v-if="$route.path.includes('History')" />

        </div>

    </div>
</div>
</template>

<script>
import PanelSidebar from './PanelSidebar'
import PanelNavbar from './PanelNavbar'
import Notifications from './Notifications'
import Rewards from './Rewards'
// import InviteFriends from './InviteFriends'
import Profile from './Profile/Profile'
import Settings from './Settings/Settings'
import Dashboard from './Dashboard/Dashboard'
import Wallets from './Wallets'
// import Authentication from './Authentication/Authentication'
// import Sidebar from './Sidebar'
import Support from './Support'
import Exchange from './Exchange'
import Trade from './Trade/Trade'
import History from './History/History'

export default {
    name: 'Panel',
    components: {
        PanelSidebar,
        PanelNavbar,
        Notifications,
        Rewards,
        Dashboard,
        Wallets,
        // Sidebar,
        // Wallet,
        // Authentication,
        Settings,
        Profile,
        Support,
        Exchange,
        Trade,
        History,
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.panel-page {
    display: flex;
    width: 100%;
}

.exchange-bg {
   
    background: url('../../assets/images/exchange-bg.png');
    background-repeat: no-repeat;
    background-size: cover;

   
}

.panel {
    flex: 1;
    width: 8vw;
    z-index: 0;

}
.panel-comp{
    padding: 12px clamp(10px, 1.5vw, 17px);

}
</style>
