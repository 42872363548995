<template>
<div class="public-page">
    <div class="page-header">Terms of Use</div>
    <div class="passage-container" v-html="privacyPolicy" />

</div>
</template>

<script>
export default {
    name: 'Terms',
    methods: {
        async getInfo() {
            const res = await this.$axios.get('/exchange-info')
            this.privacyPolicy = res.baseDTO.privacyPolicy || ''
        },
    },
    data() {
        return {
            privacyPolicy: ''
        }
    },
    mounted() {
        this.getInfo()
    }
}
</script>

<style scoped>
.page-header {
    color: #FFFFFF;
    font-size: clamp(22px, 2.8vw, 36px);
    font-weight: bold;

}
.public-page{
    background: radial-gradient(42.49% 63.74% at 50% 50%, #2A1A8F 0%, #020E38 100%);
    width: 85%;

}
.passage-container {
    color: #FFFFFF;
    border-radius: 6px;
    padding: 24px;

}
</style>
