import Signup from '../components/Auth/Signup'
import Login from '../components/Auth/Login'
import TwoStep from '../components/Auth/TwoStep'
import ChangePass from '../components/Auth/ChangePass'
import ForgetPassword from '../components/Auth/ForgetPassword'
import OTPConfirm from '../components/Auth/OTPConfirm'
import Public from '../components/Public/Public'
import Panel from '../components/Panel/Panel'
import Router from "vue-router";
import Vue from 'vue'


const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  
 routes :[
  {
    path: "/",
    name: "Public",
    component: Public,
    children:[
      {
        path: "FAQ",
        name: "FAQ",
        component: Public,
      },{
        path: "/",
        name: "home",
        component: Public,
      },{
        path: "Terms",
        name: "Terms",
        component: Public,
        
      },{
        path: "Guide",
        name: "Guide",
        component: Public,
        
      },{
        path: "Guide/:category/:article",
        name: "GuideArticle",
        component: Public,
        
      },{
        path: "Guide/:category",
        name: "GuideCategory",
        component: Public,
        
      },{
        path: "AboutUs",
        name: "AboutUs",
        component: Public,
        
      },{
        path: "ContactUs",
        name: "ContactUs",
        component: Public,
        
      },
      {
        path: "Markets",
        name: "Markets",
        component: Public,
      },
      {
        path: "Coin/:crypto",
        name: "Coin",
        component: Public,
      },
     

    ]

  }, 
   {
    path: "/Login",
    name: "Login",
    component: Login,
  },{
    path: "/SignUp",
    name: "SignUp",
    component: Signup,
    
  },{
    path: "/ChangePass/:type",
    name: "ChangePass",
    component:ChangePass,
    
  },{
    path: "/ForgetPassword",
    name: "ForgetPassword",
    component:ForgetPassword,
    
  },{
    path: "/TwoStep/:type",
    name: "TwoStep",
    props:true,
    component: TwoStep,
  },
  // {
  //   path: "/reset-pass-email",
  //   name: "ChangePass",
  //   component: ChangePass,
  // },{
  //   path: "/reset-pass-sms",
  //   name: "ChangePass",
  //   component: ChangePass,
  // },{
  //   path: "/reset-pass-google-auth",
  //   name: "ChangePass",
  //   component: ChangePass,
  // },
  {
    path: "/OTPConfirm",
    name: "OTPConfirm",
    component: OTPConfirm,
  },
  
  
  {
    path: "/Panel",
    name: "Panel",
    component: Panel,
    children:[
      {
        path: "Dashboard",
        name: "Dashboard",
        component: Panel,
      }, {
        path: "Wallets",
        name: "Wallets",
        component: Panel,
      },{
        path: "Profile",
        name: "Profile",
        component: Panel,
      },{
        path: "Support",
        name: "Support",
        component: Panel,
      },{
        path: "Rewards",
        name: "Rewards",
        component: Panel,
      },{
        path: "Settings",
        name: "Settings",
        component: Panel,
      },{
        path: "Notifications",
        name: "Notifications",
        component: Panel,
      },{
        path: "Exchange",
        name: "Exchange",
        component: Panel,
      },{
        path: "Ticket",
        name: "Ticket",
        component: Panel,
      },{
        path: "Level",
        name: "Level",
        component: Panel,
      },
      { 
        path: "Trade/TETHER/TETHER",
        redirect:'Trade/TOMAN/TETHER'
      }, 
      { 
        path: "Trade",
        redirect:'Trade/TOMAN/BITCOIN'
      },
      {
        path: "Trade/:tradeTo/:tradeFrom",
        name: "Trade",
        component: Panel,
      },
      {
        path: "History",
        name: "History",
        component: Panel,
        children:[
          {
            path: "Trades",
            name: "TradesHistory",
          },
          {
            path: "Exchange",
            name: "ExchangeHistory",
          },  
          {
            path: "Transactions",
            name: "TransactionsHistory",
          },
          {
            path: "RialWithdraw",
            name: "RialWithdraw",
          }, 
          {
            path: "RialDepsoit",
            name: "RialDepsoit",
          },

        ]
      }
    
       
    ]
  },
]
})
const that = Vue.prototype

const disabledRoutes = [
  'OTC',
  'Exchange',
]
const forLogin = [
  'History',
  'Dashboard',
  'Panel',
  'Trade',
  'Exchange',
  'TransactionsCrypto',
  'TransactionsRial',
  'Orders',
  'Wallet',
  'Settings',
  'ActiveOrders',
  'fino-verifying',
  'Notifications',
  'Orders',
  'Trade',
  'Transactions',

]
const unAuthorUser = [
  'Trade',
  'Exchange',
  'Transactions',
  'Trades',
  'Orders',
  'ActiveOrders',
  'Dashboard',
  'Wallet',
  
]
const otp = [
  'TwoStep',
]
const auth = [
  // 'Login',
  // 'SignUp',
  // 'ForgetPassword',
]
router.beforeEach(async (to, from, next) => {

  that.state.loading = true

  if(!that.state.coins){
    await that.$getCoins()
  }

  if (that.state.token) {

      if (!that.state.gotten) {
        that.state.loading = true
          await that.$getuserInfo()
          that.state.loading = true
          await that.$getFees()
      }

      that.state.loading = true
      if(that.state.userInfo.supervisor && disabledRoutes.includes(to.name)){
        that.$error('','با عرض پوزش قابلیت مورد نظر برای بازار گردان غیر فعال است')
          next(from)
      }
       if (from.name === 'Blog') {
          await window.open(to.fullPath,'_self')
      }
      else if (otp.includes(to.name) || auth.includes(to.name)) {
          next({name: 'home'})
      } 
      else if (to.name === 'Trade') {
        // if(!to.params.crypto || !to.params.currency){
        //   next('/Panel/Trade/TOMAN/BITCOIN')
        // }
        // else if(to.params.crypto=='TETHER' && to.params.currency=='TETHER' ){
        //   next('/Panel/Trade/TOMAN/TETHER')

        // }
        if(!that.state.userInfo.authorized){
           next({name: 'HomePage'})
        }
        
        else{
          try {
            that.state.loading = true
            next()
            // next(`/Panel/Trade/${to.params.currency}/${to.params.crypto}`)
        } catch (e) {
            if (e.response.data.message === "MARKET_IS_NOT_ACTIVE") {
                if (otp.includes(from.name)) {
                    next({name: 'HomePage'})
                } else {
                    await that.$error('بازار غیرفعال است')
                    next(from)
                }
            }
        }
        }
      } else {
          if (that.state.userInfo.authorized) {
              next()
          } else {
              if (otp.includes(from.name) && to.name !== 'Account') {
                  next({name: 'Account'})
              } else if (unAuthorUser.includes(to.name)) {
                  that.state.loading = false
                  const res = await that.$error('عدم دسترسی', 'برای دسترسی به این بخش نیاز است ابتدا حساب شما تکمیل و تایید شود', 'error', 'حساب کاربری')
                  if (res.isConfirmed) {
                      next('/Panel/Profile')
                  } else {
                      next(from)
                  }
              } else next()
          }
      }
  } 
  else {
      if ((otp.includes(to.name) && !Vue.$cookies.isKey('username')) || forLogin.includes(to.name))
          next({name: 'home'})
      else {
          next()
      }
  }
  that.state.loading = false
})

export default router;