import Vue from 'vue'
import App from './App.vue'
import './style/index.scss'
import './library/states.js'
import router from './library/router'
import {InlineSvgPlugin} from 'vue-inline-svg';
import VueRouter from 'vue-router'

import SwitchComponent from './components/Tools/SwitchComponent'
import VerifyCodeInput from './components/Tools/VerifyCodeInput'
import LabelInput from './components/Tools/LabelInput'
// import ToggleComp from './components/tools/ToggleComp'
// import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueApexCharts from 'vue-apexcharts'
import './library/axios'
import './library/globalFunctions'
import './library/globalConstants'
import './library/georgianToJalali'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

Vue.use(PerfectScrollbar)
Vue.component('apexchart', VueApexCharts)

// Vue.component('apexchart', VueApexCharts)
Vue.component('VerifyCodeInput',VerifyCodeInput)
Vue.component('SwitchComponent',SwitchComponent)
Vue.component('LabelInput',LabelInput)
// Vue.component('ToggleComp',ToggleComp)
// Vue.use(LottieAnimation,'LottieAnimation'); // add lottie-animation to your global scope
localStorage.lang=localStorage.lang || 'en'
Vue.use(InlineSvgPlugin);
Vue.use(VueSweetalert2);
Vue.config.productionTip = false
Vue.use(VueRouter)
new Vue({
  router,
 
  render: h => h(App),
}).$mount('#app')