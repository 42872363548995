<template>
<div class="history">
    <Transactions v-if="$route.name=='TransactionsHistory'" />
    <Trades v-if="$route.name=='TradesHistory'" />
    <Exchange v-if="$route.name=='ExchangeHistory'" />
    <RialWithdraw v-if="$route.name=='RialWithdraw'" />
    <RialDepsoit v-if="$route.name=='RialDepsoit'" />
</div>
</template>

<script>
import Transactions from './Transactions'
import Trades from './Trades'
import Exchange from './Exchange'
import RialWithdraw from './RialWithdraw'
import RialDepsoit from './RialDepsoit'
export default {
    name: 'History',
    props: {

    },
    data() {
        return {
            pages: [{
                    label: 'تاریخچه ورود به حساب کاربری',
                    value: 'logins'
                },
                {
                    label: 'واریز و برداشت',
                    value: 'transactions'
                },
                {
                    label: 'سفارشات',
                    value: 'orders'
                },
                {
                    label: 'معاملات',
                    value: 'trades'
                },

            ],

            currentTable: {
                label: 'تاریخچه ورود به حساب کاربری',
                value: 'logins'
            },

        }
    },
    components: {
        Transactions,
        Trades,
        Exchange,
        RialDepsoit,
        RialWithdraw
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
::v-deep .history-table-container {
    // max-height: 500px;
    // overflow-y: scroll;
    // width: 100%;
    // overflow: hidden;
    // margin-top: 20px;
    // border-radius: 8;
    margin-top: 16px;

}
::v-deep .green{
    color: #28F260;
}

::v-deep .red{
    color: #E30037;
}
::v-deep .filters{
    max-width: 368px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 12px;
    column-gap: clamp(12px,1.4vw,16px);
    margin-top:32px;
}
// .sf {
//     z-index: 23 !important;
// }

// ::v-deep .table-header {
//     background: linear-gradient(90.07deg, rgba(47, 43, 73, 0.95) 1.28%, rgba(75, 71, 97, 0.84) 99.22%);
//     position: sticky;
//     top: 0px;
// }

// ::v-deep td {
//     padding: 12px 0px;
// }

// ::v-deep td:first-child {
//     padding-left: 24px;
// }

// ::v-deep td:last-child {
//     padding-right: 24px;
//     text-align: right;
// }

// ::v-deep table {
//     color: white;
//     border-collapse: collapse;

// }

// ::v-deep .table-row {
//     background: #18223D;
// }

@media(max-width:1200px) {

    .resp-1 {
        display: none;
    }
}

@media(max-width:900px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width:600px) {
    .resp-3 {
        display: none;
    }
}
</style>
