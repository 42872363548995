<template>
<div class="trade-view">
    <VueTradingView v-if="cryptoSymbol" :key="cryptoSymbol" class="chart" :options="chartOptions" />

</div>
</template>

<script>
import VueTradingView from 'vue-trading-view/src/vue-trading-view';
export default {
    name: 'TradeView',
    components: {
        VueTradingView,
    },
    props: {
        cryptoSymbol: {
            type: String
        }
    },
    data() {
        return {
            chartOptions: {
                width: "100%",
                height: "417px",
                "symbol": `${this.cryptoSymbol=='USDT'?("BINANCEUS:USDTUSD"):("BINANCE:"+this.cryptoSymbol+"USDT")}`,
                "interval": "30",
                "timezone": "Etc/UTC",
                "theme": "dark",
                "style": "3",
                "locale": "en",
                "toolbar_bg": "#f1f3f6",
                "enable_publishing": false,
                "hide_top_toolbar": true,
                "hide_legend": true,
                "save_image": false,

            }

        }
    },
    mounted() {
        console.log(this.cryptoSymbol)
    }
}
</script>

<style scoped>
.trade-view {
   margin-top: 12px;
}
</style>
