<template>
<div class="coins-list">
    <div class="section-title-and-switch">
        <span>{{lang.marketsLabel}}</span>
        <SwitchComponent :defaultValue="tradeTo" :type2="true" @change="(baseCoin)=>$router.push(`/Panel/Trade/${baseCoin}/${tradeFrom}`)"  :items="tradeFrom=='TETHER'?['TOMAN']:['TOMAN','TETHER']" />

    </div>
    <table>
        <tr class="table-header">
            <td>{{lang.tableHeaders.coin}}</td>
            <td>{{lang.tableHeaders.price}}</td>
            <td>{{lang.tableHeaders.change}}</td>
        </tr>
        <tr v-for="(crypto,index) in cryptos.filter(coin=>tradeTo!=$coin2Snake[coin.relatedCoin])" :key="index" class="table-row" @click="$router.push(`/Panel/Trade/${tradeTo}/${$coin2Snake[crypto.relatedCoin]}`)">
            <td class="coin-title">
                <img class="coin-icon" :src="require('../../../../public/coins/'+crypto.relatedCoin+'.png')" />
                {{crypto.relatedCoin}}
            </td>
            <td>{{$toLocal(crypto.lastPrice,$decimal[tradeTo])}}</td>
            <td :class="{red:crypto.last24HourChange<0,green:crypto.last24HourChange>=0,}" dir='ltr'>
                <span v-if="crypto.last24HourChange>=0">+</span>
                <span v-if="crypto.last24HourChange<0">-</span>
                {{$toLocal(Math.abs(crypto.last24HourChange),2)}}%
            </td>
        </tr>
    </table>
</div>
</template>

<script>
import SwitchComponent from '../../Tools/SwitchComponent'

import {
    tradeTo,
    tradeFrom,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'CoinsList',
    props: ['cryptos'],
    components: {
        SwitchComponent,

    },
    data() {
        return {
            lang: this.$languageDict().trade.coinsList
        }
    },
    computed: {
        tradeTo,
        tradeFrom
    },
    methods: {
        async getLastTrades() {
            const res = await this.$axios.get('/trades/last-trades?type=P2P', {
                params: {
                    marketType: this.tradeFrom + '_' + this.tradeTo,
                    size: 18
                }
            })
            this.lastTrades = res.content
            this.loading = false
        },
    },
    async mounted() {
        this.loading = true
        if (this.state.loop) {
            this.loop = new Loop(this.getLastTrades, 5000, 'realPrice' + (this.tradeFrom))
            this.loop.start()
        } else {
            this.getLastTrades()

        }

    },
    beforeDestroy() {
        this.loop.stop()
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.coin-title {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.section-title-and-switch {
    font-size: clamp(16px, 1.8vw, 20px);
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    color: white;
    align-items: center;
}

.coins-list {
    background: #1D2050;
    border-radius: 8px;
    padding: 16px 8px 8px 8px;
}

.dropdown {
    padding: 0px 16px;
    border-radius: 20px;

}

.coin-icon {
    width: clamp(18px, 2.1vw, 24px);
}

.icon {
    stroke: var(--dropdown-icon)
}

.table-header {
    color: #BDBDBD;
    font-size: clamp(11px, 1.2vw, 13px);
}

table {
    width: 100%;
    margin-top: 28px;
    border-collapse: collapse;
    text-align: center;

}

td {
    padding: 10px 0px;
}

.green {
    color: #80B73B;
}

.red {
    color: #DD1B60;
}

td:last-child {
    border-radius: 0px 6px 6px 0px;
    text-align: right;
    padding-right: 4px !important;

}

td:first-child {
    text-align: left;
    border-radius: 6px 0px 0px 6px;
    padding-left: 4px !important;

}

.table-row {
    color: #F2F2F2;
    font-size: clamp(12px, 1.3vw, 14px);
    cursor: pointer;

    &:hover {
        background: rgba(251, 250, 250, 0.8);
        color: #CB07C3;

    }

}
</style>
