<template>
<div class="homepage">
    <Banner />
    <CryptoCarousel />
    <Markets />
    <OurOffer />
    <TradeDetails />
    <BlogPosts />
    <div class="help-container">
        <div class="help">
            <div class="help-text">
                {{language.helpSuggest}}
            </div>
            <img class="helping-wolf" src='../../../assets/icons/helping-wolf.svg' />
        </div>
    </div>
    <div class="join-suggest">
        <div class="suggest">{{language.joinSuggest.suggest}}</div>
        <div class="btns">
            <button class="login-btn btn"  @click.prevent="$router.push('/Login')" >
                <span class="gradiant-font">{{language.joinSuggest.login}}</span>
            </button>
            <button class="signup-btn btn"  @click.prevent="$router.push('/SignUp')">{{language.joinSuggest.signUp}}</button>
        </div>
    </div>
</div>
</template>

<script>
import TradeDetails from './TradeDetails'
import CryptoCarousel from './CryptosCarousel'
import OurOffer from './OurOffer'
import Markets from './Markets'
import BlogPosts from './BlogPosts'
import Banner from './Banner'
export default {
    name: 'homepage',
    components: {
        Banner,
        CryptoCarousel,
        TradeDetails,
        Markets,
        OurOffer,
        BlogPosts
    },
    data() {
        return {
            language: this.$languageDict().homepage,

        }
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.help-container {
    max-width: clamp(160px,18vw,220px);
    position: fixed;
    z-index: 40;
    right: 3%;
    top: 60%;
}

.help {
    display: flex;
    align-items: center;
    background: linear-gradient(95.44deg, #FF109F -3.76%, #8D18EA 57.54%, #5200FF 106.46%);
    border-radius: 12px;
    padding: clamp(18px,1.9vw,22px) 10px clamp(18px,1.9vw,22px) 16px;
    font-size: clamp(14px, 1.7vw, 20px);
    color: #FFFFFF;
    position: relative;
}

.helping-wolf {
    position: absolute;
    width: clamp(74px,44%,96px);
    right: 10%;
    top: -60%;
    margin-right: -10px;
}

.homepage {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
    background: url('../../../assets/images/homepage-background.png');
    background-size: cover;
    background-repeat: no-repeat;

}

.homepage>* {
    width: 85%;
}

.suggest {
    color: #FFFFFF;
    font-size: clamp(24px, 3vw, 36px);
}

.join-suggest {
    padding: clamp(30px, 4.3vw, 58px) clamp(16px, 1.7vw, 24px);
    background: linear-gradient(180deg, rgba(11, 20, 64, 0.66) 0%, #0B133C 100%);
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 14px;
    column-gap: 18px;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 80px;
}

.btns {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    flex-grow: 1;
    max-width: 400px;
}

.login-btn {
    background: rgba(27, 152, 192, 0.47);
}

.gradiant-font {
    background: linear-gradient(93.29deg, #F82EA7 1.86%, #A30BEB 97.42%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.signup-btn {
    background: linear-gradient(90deg,
            #1a97bf 2.72%,
            #721ac9 28.48%,
            #ab0ae3 62.88%,
            #c102c5 78.84%,
            #bb1396 100%);
    color: #fcfcfc;
}

.btn {
    flex-grow: 1;
    border-radius: 8px;
    padding: 10px 25px;
    font-size: clamp(14px, 1.5vw, 16px);
}
</style>
