<template>
<div class="auth-page">
    <div class="auth-card">
        <div class="auth-card-title">{{lang.title}}</div>
        <form class="auth-form" @submit.prevent="send()">
            <LabelInput @input="value=>{data.email=value;emailError=''}" :errorText="emailError" :label="lang.emailLabel" />
            <LabelInput @input="value=>{data.password=value;}" type='password' :label="lang.passwordLabel"  />
            <button :class="{disabled:!data.email ||!data.password  }" 
            :disabled="!data.email ||!data.password " type="submit"  class="auth-confirm-btn">{{lang.confirmBtn}}</button>
        </form>
        <div class="auth-card-links">
            <a @click.prevent="$router.push('/ForgetPassword')" href="/ForgetPassword"   class="auth-link">{{lang.forgetPassLink}}</a>
            <a  @click.prevent="$router.push('/SignUp')" href="/SignUp"  class="auth-link">{{lang.registerLink}}</a>
        </div>
        <button class="auth-google-btn"  @click="googleSso()">
            <span>{{lang.googleBtnLabel}}</span>
            <img src='../../assets/icons/google.svg' />
        </button>
    </div>

</div>
</template>

<script>
//  const verifyType = {
//         EMAIL: 'Email',
//         SMS: 'SMS',
//         GOOGLE_AUTHENTICATOR: 'GoogleAuth',
//     }
export default {
    name: 'Login',
    data() {
        return {
            lang: this.$languageDict().login,
             data:{
                    email: '',
                    password: '',
                    osType: '',
                    deviceType: '',
                    browser: '',
                },
                    show: false,
                    emailError: '',
                    passwordError: '',

        }
    },

  methods:{
        googleSso() {
            window.location.href = this.$google_sso
        },
        async send() {

            await this.checkEmail()
            this.state.loading = true
            const res = await this.$axios.post('/users/login', this.data)
            if (res.message === 'login successful, otp is required') {

                this.$cookies.set('username', this.data.email)
                this.state.userInfo.email = this.data.email
                this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
                // this.$router.push('/TwoStep/' + verifyType[res.baseDTO.towStepAuthenticationType])
                await this.$router.push('/Panel/Profile')


            } else if (res.token) {
                this.$setToken(res.token, this.state.userInfo.remember)
                await this.$router.push('/Panel/Dashboard')
            }

        },
        async checkEmail() {
            if (!this.$validEmail(this.data.email) || !this.data.email) {
                this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                return Promise.reject('repeat failed : ' + this.data.email)
            }
        },
        getData() {
            this.data.osType = this.$detectOs()
            this.data.browser = this.$detectBrowser() || 'CHROME'
            this.data.deviceType = this.$detectDevice()
        },
    },
    mounted() {
        this.getData()
    }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
