<template>
<ModalCard @close="$emit('modalToggle','')">
    <template #title>
        {{lang.modalTitle}}
    </template>
    <template #body>
        <div class="details">
            <div class='detail'>
                <span class="detail-label">{{lang.request}}</span>
                <span  class="detail-value" :class="{buy:order.tradeType=='BUY' ,sell:order.tradeType=='SELL'}">{{order.tradeType+' '+order.orderType}}</span>
            </div>
            <div class='detail'>
                <span class="detail-label"> {{lang.amount}}</span>
                <span  class="detail-value" dir="ltr">{{order.amount}}&nbsp;{{$coins[order.coin].code}}</span>
            </div>
            <div class='detail'>
                <span class="detail-label">{{lang.unitPrice}}</span>
                <span  class="detail-value">{{ $toLocal(order.unitPrice,$decimal[order.baseCoin])}}&nbsp;{{$coins[order.baseCoin].code}}</span>
            </div>
            <div class='detail'>
                <span class="detail-label"> {{lang.total}}</span>
                <span class="detail-value">{{$toLocal(sum,$decimal[order.baseCoin])}}&nbsp;{{$coins[order.baseCoin].code}}</span>
            </div>

        </div>
        <button class="modal-submit-btn">{{lang.confirmBtn}} </button>
    </template>

</ModalCard>
</template>

<script>
import ModalCard from './ModalCard'
export default {
    name: 'success',
    components: {
        ModalCard,
    },
    computed:{
        sum(){
          return this.order.unitPrice * this.order.amount
      }
    },
    props: {
        order: Object
    },
    data() {
        return {
            lang:this.$languageDict().successModal,
            dict: {
                "MARKET": 'بازار',
                "LIMIT": 'حد',
                "EXCHANGE": 'صرافی',
                "BUY": 'خرید',
                "SELL": 'فروش'
            }

        }
    }
}
</script>

<style scoped>

.details {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
.buy{
    color: green!important;
}
.sell{
    color: #DD1B60!important;
}


.detail-label {
    font-size: clamp(12px, 1.3vw, 14px);
    color: #E0E0E0;
}

.detail-value {
    font-size: clamp(12px, 1.3vw, 14px);
    color: #FFFFFF;
}
.detail{
    display: flex;
    justify-content: space-between;
}
.detail-tag {
    color: #E0E0E0;
    font-size: clamp(12px, 1.3vw, 14px);

}
</style>
