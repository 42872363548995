<template>
<div class="auth-page">
    <div class="auth-card">
        <div class="auth-card-title">{{lang.title}}</div>
        <form class="auth-form" @submit.prevent="send()">
            <LabelInput @input="value=>email=value" :label="lang.emailLabel" />
            <div class="auth-card-text">{{lang.text}}</div>
            <button :class="{disabled:!email}" :disabled="!email" type="submit" class="auth-confirm-btn">{{lang.confirmBtn}}</button>
        </form>

    </div>

</div>
</template>

<script>
const verifyTypes = {
    EMAIL: 'Email',
    SMS: 'SMS',
    GOOGLE_AUTHENTICATOR: 'GoogleAuth',
}
export default {
    name: 'ForgetPassword',
    data() {
        return {
            lang: this.$languageDict().forgetPassword,
            goto: {
                EMAIL: '/email',
                SMS: '/sms',
                GOOGLE_AUTHENTICATOR: 'google-auth',
            },
            email: '',
            emailError: '',

        }
    },
    methods: {
        checkEmail() {
            if (!this.email) {
                this.emailError = 'ایمیل اجباری';
            } else if (!this.validEmail(this.email)) {
                this.emailError = 'ایمیل صحیح وارد نمایید';
            } else {
                this.emailError = ''
            }
            if (!this.emailError.length) {
                return true;
            }
        },
        validEmail(email) {
            let re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            return re.test(email);
        },
        receive(e) {
            this.email = e
            this.checkEmail(e)
        },
        async send() {
            if (this.checkEmail(this.email)) {
                await this.checkEmail()
                this.state.loading = true
                const res = await this.$axios.post('/users/forget-password?email=' + this.email)
                this.$cookies.set('username', this.email)
                this.state.userInfo.email = this.email
                if (res.baseDTO) {
                    this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
                    this.state.userInfo.towStepAuthenticationType = verifyTypes[res.baseDTO.towStepAuthenticationType];
                } else {
                    this.state.userInfo.towStepAuthenticationType = 'Email'
                }
                await this.$router.push('/ChangePass/'+this.goto[res.baseDTO.towStepAuthenticationType])

            }
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
