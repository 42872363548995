<template>
<div class="wallets">
    <div class="preview-section">
        <div class="total-value-label">
            {{lang.totalValueLabel}}
            <div class="filter">
                <SwitchComponent @change="value=>marketType=value" :items="['P2P','EXCHANGE']" />
            </div>
        </div>
        <div class="total"><span class="total-value">{{$toLocal(totalPriceOfCredits,0)}}</span>&nbsp;<span class="total-tag">TOMANs</span></div>
        <div class="toman-box ">
            <div class="toman-balance-flag">
                <img class="toman-icon" src='../../../public/coins/TOMAN.png' />
                <div class="toman-balance">
                    <div class="toman-balance-label">{{lang.currentBalanceLabel}}</div>
                    <div class="toman-balance-value">{{$toLocal(tomanBalance,0)}} TOMANs</div>
                </div>
            </div>
            <div class="btns">
                <button class="btn toman-withdraw"  @click="modalType='RialWithdraw'">{{lang.withdraw}}</button>
                <button class="btn toman-depsoit"  @click="modalType='RialDepsoit'">{{lang.depsoit}}</button>
            </div>
        </div>
    </div>
    <div class="wallets-container">
        <div class="wallet-table">
            <div class="table-row">
                <div>{{lang.tableHeaders.coin}}</div>
                <div>{{lang.tableHeaders.balance}}</div>
                <div class="resp-2">{{lang.tableHeaders.equivalentTo}}<br>(Tomans)</div>
                <div class="resp-1">{{lang.tableHeaders.available}}</div>
                <div class="resp-3" align='left'>{{lang.tableHeaders.actions}}</div>
            </div>
            <div class="table-row row-bg" v-for="(coin,index) in sorted" :key="index">
                <div width="20%">
                    <div class="coin-title-icon">
                        <img class="crypto-icon " :src="require('../../../public/coins/'+coin[0]+'.png')" />
                        <div class="coin-title">{{coin[0]}}</div>
                        <div class="coin-symbol">{{$coins[coin[0]].code}}</div>
                    </div>
                </div>
                <div>{{$toLocal(coin[1].credit,$decimal[coin[0]])}}</div>
                <div class="resp-2">{{$toLocal(coin[1].wholePriceOfCredit,0)}}</div>
                <div class="resp-1">{{$toLocal(coin[1].credit-coin[1].blockedCredit,$decimal[coin[0]])}}</div>
                <div align='left' width="30%">
                    <div class="transaction-btns">
                        <button class="transaction-btn withdraw" @click="openWithdrawModal(coin[0],coin[1].credit)">
                            {{lang.withdraw}}
                            <div class="line" />
                        </button>
                        <button class="transaction-btn depsoit"  @click="relatedCoin=coin[0];modalType='CryptoDepsoit'">
                            {{lang.depsoit}}
                            <div class="line" />
                        </button>
                        <button class="transaction-btn trade" @click="$router.push(`/Panel/Trade/TOMAN/${coin[0]}`)">
                            {{lang.trade}}
                            <div class="line" />
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <RialWithdraw @modalToggle="(value)=>modalType=value" v-if="modalType=='RialWithdraw'" :cash="tomanBalance" />
    <RialDepsoit @modalToggle="(value)=>modalType=value" v-if="modalType=='RialDepsoit'" />
    <CoinDepsoit @modalToggle="(value)=>modalType=value" :exchange='marketType=="EXCHANGE"' :relatedCoin="relatedCoin" v-if="modalType=='CryptoDepsoit'" />
    <CoinWithdraw @modalToggle="(value)=>modalType=value" v-if="modalType=='CRYPTOWITHDRAW'" :relatedCoin="relatedCoin" :credit="credit" :exchange='marketType=="EXCHANGE"' />

</div>
</template>

<script>
import RialWithdraw from '../Modals/RialWithdraw.vue'
import RialDepsoit from '../Modals/RialDepsoit.vue'
import CoinWithdraw from '../Modals/CoinWithdraw'
import CoinDepsoit from '../Modals/CoinDepsoit'
export default {
    name: 'Wallets',
    components:{
        RialWithdraw,
        RialDepsoit,
        CoinWithdraw,
        CoinDepsoit
    },
    data() {
        return {
            lang: this.$languageDict().wallets,
            wallets: {},
            modalType: '',
            totalPriceOfCredits: 0,
            marketType: "P2P",

        }
    },
    computed: {
        tomanBalance() {
            if (this.wallets['TOMAN']) {
                const temp = this.wallets['TOMAN']
                return temp.credit
            }
            return 0
        },
        sorted() {
            let entireRes = Object.entries(this.wallets)
            const sortCoins = this.$walletSortCoins
            entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
            return entireRes.filter(coin => coin[0] !== 'TOMAN')

        },
    },
    watch: {
        marketType() {
            if (this.marketType == 'EXCHANGE')
                this.getExchangeCoins()
            else
                this.getP2PCoins()
        }
    },
    methods: {
        async getP2PCoins() {
            this.totalPriceOfCredits = 0
            this.loading = true
            this.state.loading = true
            let cryptos
            let crypto
            const [res, ] = await this.$http.get(`/wallets/customer-addresses?walletType=P2P`)
            if (res) {
                this.wallets = res
            }
            await this.$axios.get('/trades/market-info', {
                params: {
                    weeklyDataIncluded: false,
                }
            }).then(response => cryptos = response.content.filter(coin => coin.marketType.includes('TOMAN')))
            for (const [key, value] of Object.entries(this.wallets)) {

                if (key !== 'TOMAN') {
                    crypto = cryptos.find(coin => coin.marketType.includes(key))
                    if (crypto) {
                        value.buyPrice = crypto.buyPrice
                        value.change = crypto.last24HourChange
                        value.price = crypto.lastPrice

                        this.totalPriceOfCredits += value.wholePriceOfCredit
                    }
                }
                this.loading = false
                this.state.loading = false
            }

        },

        async getExchangeCoins() {
            this.totalPriceOfCredits = 0
            this.loading = true
            this.state.loading = true

            let cryptos
            let crypto
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE')
            if (res) {
                this.wallets = res
            }
            await this.$axios.get('coins/exchange-price/usd', {
                params: {
                    includeWeeklyChange: false
                }
            }).then(response => cryptos = response)

            for (const [key, value] of Object.entries(this.wallets)) {
                if (key !== 'TOMAN') {
                    crypto = cryptos.find(coin => coin.destCoinSymbol === key)
                    if (crypto) {
                        value.buyPrice = crypto.buyPrice
                        value.change = crypto.price24hrChangePercent
                        value.price = crypto.price
                        this.totalPriceOfCredits += value.wholePriceOfCredit

                    }

                }
            }
            this.loading = false
            this.state.loading = false

        },
        async gedivata() {

            const res = await this.$axios.get('/users/invites');
            this.inviteData = res.baseDTO;

        },
        openWithdrawModal(code, credit) {
            this.relatedCoin = code;
            this.credit = credit
            console.log(credit)
            this.modalType = 'CRYPTOWITHDRAW';
        },

        openRialWithdrawModal() {
            this.modalType = 'RialWithdraw';
        },
    },
    mounted() {
        this.getP2PCoins()
        this.gedivata()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.wallets-container {
    margin-top: 8px;

    .wallet-table {

        text-align: center;
        color: #FFFFFF;
        font-size: clamp(14px, 1.6vw, 18px);
        width: 100%;
        // border-collapse: separate;
        // border-spacing: 0 8px;

        .row-bg {
            background: linear-gradient(154.53deg, rgba(111, 111, 116, 0.28) -41.13%, rgba(61, 62, 96, 0.16) 51.63%, rgba(25, 26, 37, 0.11) 122.86%);
            border-radius: 8px;
            padding: 12px;
        }

        .table-row {

            margin-top: 8px;
            display: grid;
            grid-template-columns: 5fr repeat(3, 3fr) 6fr;
            align-items: center;
            padding-right: clamp(24px, 2.8vw, 36px);
            row-gap: 15px;

            @media screen and (max-width:910px) {
                grid-template-columns: 5fr repeat(2, 3fr) 6fr;
            }

            @media screen and (max-width:840px) {
                grid-template-columns: 5fr repeat(1, 3fr) 6fr;
            }

            @media screen and (max-width:680px) {
                grid-template-columns: 4fr repeat(1, 3fr);
            }

            .resp-1 {
                @media screen and (max-width:910px) {
                    display: none;
                }
            }

            .resp-2 {
                @media screen and (max-width:840px) {
                    display: none;
                }
            }

            .resp-3 {
                @media screen and (max-width:680px) {
                    display: none;
                }

            }

            .coin-title-icon {
                display: flex;
                align-items: center;
                column-gap: 6px;

                .crypto-icon {
                    width: clamp(24px, 3.2vw, 40px);

                    @media screen and (max-width:480px) {
                        display: none;
                    }
                }

                .coin-title {
                    font-size: clamp(14px, 1.7vw, 20px);
                    font-weight: bold;
                }

                .coin-symbol {
                    color: #E0E0E0;
                }

            }

            .transaction-btns {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .transaction-btn {
                    font-weight: bold;
                    background: transparent;
                    text-align: left;
                    font-size: clamp(14px, 1.6vw, 18px);

                    &:hover .line {
                        width: 100%;
                    }

                    .line {
                        margin-top: 2px;
                        border-radius: 2px;
                        height: 2px;
                        width: 15%;
                        transition: width 0.4s ease;
                    }
                }

                .withdraw {
                    color: #82112B;

                    .line {
                        background: #82112B;
                    }

                    &:hover {
                        color: #EB5757;

                        .line {
                            background: #EB5757;
                        }
                    }
                }

                .depsoit {
                    color: #25D656;

                    .line {
                        background: #25D656;
                    }

                    &:hover {
                        color: #2bfd67;

                        .line {
                            background: #2bfd67;
                        }
                    }
                }

                .trade {
                    color: #F2F2F2;

                    .line {
                        background: #F2F2F2;
                    }

                    &:hover {
                        color: #dadada;

                        .line {
                            background: #dadada;
                        }
                    }
                }

            }

        }

    }

}

.preview-section {
    background: url('../../assets/images/wallet-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 27px 12px 16px 12px;
    overflow: hidden;
    border-radius: 8px;
}

.total-value-label {
    display: flex;
    column-gap: 8px;
    font-size: clamp(15px, 1.6vw, 18px);
    color: #F2F2F2;
    font-weight: bold;
    margin-left: clamp(5px, 0.9vw, 13px);

    .filter {
        display: flex;
        justify-content: flex-start;

    }

}

.balance-label {
    color: #F2F2F2;
    font-size: clamp(15px, 1.6vw, 18px);

}

.balance-value {
    font-size: clamp(15px, 1.6vw, 18px);
    color: #FFFFFF;
}

.toman-icon {
    width: clamp(38px, 4.6vw, 56px);
}

.toman-balance-flag {
    display: flex;
    align-items: center;
    column-gap: 16px;
    height: 100%;
}

.total {
    margin-top: 16px;
    display: flex;
    align-items: center;
    margin-left: clamp(5px, 0.9vw, 13px);

    .total-value {
        color: #FFFFFF;
        font-size: clamp(20px, 2.7vw, 36px);
    }

    .total-tag {
        font-size: clamp(16px, 2vw, 24px);
        color: #FFFFFF;
    }

}

.toman-box {
    margin-top: 20px;
    padding: 8px 12px;
    background: linear-gradient(90deg, rgba(65, 67, 73, 0.1887) 0%, rgba(61, 66, 74, 0.37) 100%);
    border-radius: 8px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    column-gap: 25px;
    row-gap: 20px;
    align-items: center;
}

.btns {
    max-width: 380px;
    flex-grow: 1;
    column-gap: 16px;
    justify-content: space-between;
    display: flex;

    .btn {
        flex-grow: 1;
        height: 35px;
        padding: 0px 18px;
        border-radius: 8px;
        color: #FCFCFC;

    }

    .toman-withdraw {
        background: #82112B;
    }

    .toman-depsoit {
        background: #02A830;
    }
}

.toman-balance {
    display: flex;
    height: 100%;
    row-gap: 12px;
    flex-direction: column;

    .toman-balance-label {
        color: #F2F2F2;
        font-size: clamp(15px, 1.6vw, 18px);
    }

    .toman-balance-value {
        color: #FFFFFF;
        font-size: clamp(15px, 1.6vw, 18px);
    }

}
</style>
