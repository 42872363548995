<template>
<div class="label-input" :class="{'disabled-input ':disabled}">
    <label>{{label}}</label>
    <div class="input-container">
        <input id='calendar-input' />
        <div class="input-icon" :class="{'pers-input-icon':state.lang=='fa'}">
            <inline-svg class="calendar-icon" :src="require('../../assets/icons/calendar.svg')" />
        </div>
        <date-picker class="calender-comp" color='#143B74' v-model="date" custom-input="#calendar-input" :disabled="disabled" :type="isBirth?'date':'datetime'" :format="$dateFormat['en']" :display-format="$dateFormat[isBirth?'fa':'faDateTime']" :max="(isBirth)?$jmoment().format($dateFormat['en']):''" :min="(isBirth)?'':$jmoment().format($dateFormat['en'])"></date-picker>

    </div>

</div>
</template>

<script>
import DatePicker from 'vue-persian-datetime-picker'
export default {
    name: 'Calendar',
    components: {
        DatePicker
    },
    props: {
        label: String,
        type: String,
        value: String,
        disabled: Boolean,
        isBirth: Boolean
    },
    watch: {
        'date'() {
            if (this.date)
                this.$emit('input', this.date)
        },
        'value'() {
            this.date = this.value
        }
    },
    data() {
        return {
            passShow: false,
            date: this.value
        }
    },
}
</script>

<style scoped>
.label-input {
    /* width: 100%; */
    border-radius: 4px;
    display: flex;
    text-align: right;
    background: #060D35;

    flex: 3;
}

input {
    height: 42px;
    flex-grow: 1;
    size: 1;
    width: 60px;
    color: #FCFCFC;
    background: transparent;

}

.input-container {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
}

label {
    border-radius: 0px 50px 50px 0px;
    font-size: clamp(12px, 1.3vw, 13px);
    color: #BDBDBD;
    display: flex;
    padding: 0px 10px 0px 10px;
    align-items: center;
    flex-grow: 1;
    white-space: nowrap;

}

.input-icon {
    display: flex;
    align-items: center;
    background: #464956;
    border-radius: 0px 4px 4px 0px;
    cursor: pointer;
    padding: 0px 12px;
    height: 100%;

}
.pers-input-icon{
    border-radius: 4px 0px 0px 4px;

}

.calendar-icon {}

.disabled-input {
    background: #13142A;
}

.calender-comp {
    opacity: 1;
    direction: ltr;
    color: black;
    position: absolute;
    height: auto;
    width: 100%;
}
</style>
