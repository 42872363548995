<template>
<div class="guide-header">
    <div class="guide-header-content">
        <img class="teaching-wolf" src='../../../assets/images/teaching-wolf.png' />
        <div class="page-title">{{lang.pageTitle}}</div>
        <SearchBox @search="value=>regex=value"/>
        <div class="questions-sec">
            <div class="questions-sec-title">
                {{lang.popularQuestions}}
            </div>
            <div class="questions">
                <div v-for="(category,index) in questionsQuery" class="questions" :key="index+'c'">
                    <a v-for="(question,index) in category[1]" class="question-box"
                     :key="index" @click="$router.push('/guide/'+category[0]+'/'+question.name)"
                     :href="'/guide/'+category[0]+'/'+question.name">
                        <div>{{question.name}}</div>
                        <inline-svg :src="require('../../../assets/icons/arrowdown.svg')" class="arrow-icon" />
                    </a>

                </div>
            </div>

        </div>

    </div>

</div>
</template>

<script>
import SearchBox from '../../Tools/SearchBox'
export default {
    name: 'GuideHeader',
    components: {
        SearchBox
    },
    computed: {
        questionsQuery() {
            return this.$languageDict().guide.categories.map(category =>
               [category.name,category.articles.filter(article => article.name.toLowerCase().includes(this.regex))]
               )
        }
    },
    data() {
        return {
            lang: this.$languageDict().guide,
            regex: ''

        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.questions-sec {
    width: 100%;
    margin-top: 30px;
}

.questions-sec-title {
    font-size: clamp(20px, 2.1vw, 24px);
    color: #FFFFFF;
    margin-bottom: 24px;
    font-weight: bold;
}

.page-title {
    font-size: clamp(23px, 3.2vw, 36px);
    color: white;
    font-weight: bold;
    padding: 20px 0px 40px 0px;
}

.guide-header-content {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.guide-header {
    padding: 130px 0px 24px 0px;
    background: radial-gradient(42.49% 63.74% at 50% 50%, #2A1A8F 0%, #020E38 100%);
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.teaching-wolf {
    max-width: 100%;
}

.arrow-icon {
    fill: white;
    width: 20px;
    transform: rotate(-90deg);
}

.question-box {
    cursor: pointer;
    background: #060D35;
    border-radius: 4px;
    height: 52px;
    padding: 0px 16px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    color: #F2F2F2;
    font-size: clamp(14px, 1.5vw, 16px);
    justify-content: space-between;
    column-gap: 32px;
}

.question-box:hover {
    color: #CB07C3;
}

.question-box:hover .arrow-icon {
    fill: #CB07C3;
}

.questions {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;
}
</style>
