<template>
<div class="price-details">
    <div class="price-chart-sec">
        <div class="sect-title">{{$coin2Snake[crypto.relatedCoin]}}/{{crypto.relatedCoin=='USDT'?'USD':'USDT'}}&nbsp;{{lang.priceChartLabel}}</div>
        <TradingView v-if="crypto.relatedCoin" :cryptoSymbol="crypto.relatedCoin"/>
    </div>
    <div class="price-statis-sec">
        <div class="sect-title">{{lang.priceStatisLabel}}</div>
        <div class="statistics-box">
            <div class="label-value">
                <span class="label">{{lang.price}}</span>
                <span class="value">{{$toLocal(crypto.lastPrice,0)}}&nbsp;Tomans</span>
            </div>
            <div class="label-value">
                <span class="label change-label">
                    {{lang.priceChange}}
                    <SwitchComponent @change="value=>timeRange=value" :items="timeRanges"/>
                </span>
                <span class="value" :class="{'dark-red':crypto.last24HourChange<0,'dark-green':crypto.last24HourChange>0}">
                        <span v-if="crypto.last24HourChange>0">+</span><span v-if="crypto.last24HourChange<0">-</span>
                        {{Math.abs(crypto.last24HourChange).toFixed(2)}} %
                </span>
            </div>
            <div class="label-value">
                <span class="label">{{lang.marketCapacity}}</span>
                <span class="value">{{$toLocal(crypto.maxPrice,2)}}&nbsp;Tomans</span>
            </div>
            <div class="label-value">
                <span class="label">{{lang.tradingVolume}}&nbsp;({{timeRange}})</span>
                <span class="value">{{$toLocal(crypto.last24HourVolume,0)}}&nbsp;Tomans</span>
            </div>
            <div class="label-value">
                <span class="label">{{lang.High_Low}}</span>
                <span class="value">
                    Tomans&nbsp;
                    <span class="green">{{$toLocal(crypto.maxPrice,0)}}</span>/
                    <span class="red">{{$toLocal(crypto.minPrice,0)}}</span>
                </span>
            </div>
            <div class="label-value">
                <span class="label">{{lang.maxSupply}}</span>
                <span class="value">{{$toLocal(crypto.minPrice,0)}}&nbsp;{{crypto.relatedCoin}}</span>
            </div>
            <div class="label-value">
                <span class="label">{{lang.totalSupply}}</span>
                <span class="value">{{$toLocal(crypto.minPrice,0)}}&nbsp;{{crypto.relatedCoin}}</span>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import TradingView from './TradingView'
export default {
    name: 'Coin',
    props: ['crypto'],
    components:{
        TradingView
    },
    data() {
        return {
            lang: this.$languageDict().coin.priceDetails,
            timeRanges:[
                "24h",
                "1D",
                "7D"
            ],
            timeRange:"24h"

        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.sect-title{
    font-weight: bold;
    color: #F2F2F2;
    font-size: clamp(15px,16vw,18px);
}
.price-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 16px;
    row-gap: 50px;
    margin-top: 40px;
}
.label-value{
    display: flex;
    justify-content: space-between;
    column-gap: 8px;
    flex-grow: 1;
}
.price-chart-sec{
    flex-grow: 3;
    flex-basis: 300px;
}
.price-statis-sec{
    flex-grow: 2;
    flex-basis: 350px;

}
.statistics-box {
    margin-top:12px;
    display: flex;
    padding: 24px;
    flex-grow: 1;
    flex-direction: column;
    row-gap: 33px;
    background: #18223D;
    border-radius: 8px;
}

.label {
    
    color: #E0E0E0;
    font-size: clamp(14px, 1.6vw, 18px);
    font-weight: bold;
    white-space: nowrap;
}
.change-label{
    display: flex;
    column-gap: 12px;
}

.value {
    font-size: clamp(14px, 1.6vw, 18px);
    color: #F2F2F2;

}

.dark-green {
    color: #219653;

}

.dark-red {
    color: rgb(143, 4, 4);
}
.green {
    color: #28F260;


}

.red {
    color: red;
}
</style>
