<template>
<div class=" table-section">
    <div class="table-title">{{lang.tableTitle}}</div>
    <div class="tables">
    <table class="sell-table">
        <tr class=" table-row">
            <td>{{lang.tableHeaders.price}}({{lang[tradeTo]}})</td>
            <td>{{lang.tableHeaders.amount}}({{$coins[tradeFrom].code}})</td>
            <td class="resp">{{lang.tableHeaders.total}}</td>
        </tr>
        <tr class=" table-row " :style="{background:
            `linear-gradient( to left, rgba(130, 17, 43, 1) 0%, rgba(130, 17, 43, 1) ${order.executedPercent}%,
            rgba(24, 34, 61, 1) ${order.executedPercent}%)`}" v-for="(order,index) in tableContentsSell" :key="index">
            <td>{{$toLocal(order.unitPrice,0)}}</td>
            <td>{{$toLocal(order.volume,$decimal[tradeFrom])}}</td>
            <td class=" resp">{{$toLocal(order.wholePrice,0)}}</td>
            <!-- <div class="grad-bg red-bg" :style="{width:order.executedPercent+'%'}" /> -->
        </tr>

    </table>
    <table class="buy-table">
        <tr class=" table-row ">
            <td>{{lang.tableHeaders.price}}({{lang[tradeTo]}})</td>
            <td>{{lang.tableHeaders.amount}}({{$coins[tradeFrom].code}})</td>
            <td class="resp">{{lang.tableHeaders.total}}</td>
        </tr>
        <tr class="table-row" :style="{background:
            `linear-gradient( to left, rgba(1, 121, 70, 1) 0%, rgba(1, 121, 70, 1) ${order.executedPercent}%,
            rgba(24, 34, 61, 1) ${order.executedPercent}%)`}" v-for="(order,index) in tableContentsBuy" :key="index">
            <td>{{$toLocal(order.unitPrice,0)}}</td>
            <td>{{$toLocal(order.volume,$decimal[tradeFrom])}}</td>
            <td class="light-font resp">{{$toLocal(order.wholePrice,0)}}</td>
        </tr>

    </table>

    </div>

</div>
</template>

<script>
import {
    tradeTo,
    tradeFrom,
} from "@/library/reuseableFunction";
export default {
    name: 'OrderBookTable',
    props: ['tableContentsBuy', 'tableContentsSell'],
    computed: {
        tradeTo,
        tradeFrom
    },
    data() {
        return {
            lang:this.$languageDict().trade.orderBook,
            choice: this.default || '',
            dropOpen: false,
        }
    },
    // methods: {
    //     filterOrder(tradeType) {
    //         return this.orders.filter(order => {
    //             if (this.$coinUnit[order.coinName] == this.coin.symbol &&
    //                 tradeType == order.tradeType)
    //                 return order
    //         })
    //     }
    // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
table{
    border-collapse: collapse;
    flex-grow: 1;
    flex-basis: 260px;
}
.table-title {
    background: linear-gradient(90.07deg, rgba(47, 43, 73, 0.95) 1.28%, rgba(75, 71, 97, 0.84) 99.22%);
    border-radius: 8px 8px 0px 0px;
    padding: 16px;
    font-weight: bold;
    font-size: clamp(16px,1.8vw,20px);
    color: white;
}


.table-row {
    font-size: clamp(12px, 1.3vw, 14px);
    color: #BDBDBD;
    background-attachment:fixed;
    text-align: center;

}
td{
    padding: 9px 0px;
}
.table-row > td:first-child{
    color: white;
    text-align: left;
}
.table-row > td:last-child{
    text-align: right;
}

.tables{
    background: #18223D;
    padding: 16px;
    display: flex;
    column-gap: 16px;
    align-items: flex-start;
    flex-wrap: wrap;
    row-gap: 16px;
}
.table-section{
    flex-grow: 1;
    flex-basis: 300px;


}
</style>
