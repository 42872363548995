<template>
<div class="search-box">
    <input :placeholder="lang.searchPlaceHolder" v-model="regex" />
    <button class="search-btn" @click="$emit('search',regex.toLowerCase())">
        <img src="../../assets/icons/search.svg" />
    </button>

</div>
</template>

<script>
export default {
    name: 'Guide',

    data() {
        return {
            lang:this.$languageDict().guide,
            regex:''

        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.search-box {
    max-width: 491px;
    width: 100%;
    padding: 4px;
    background: #060D35;
    display: flex;
    border-radius: 4px;
    height: 48px;
    min-width: 170px;
}

.search-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #CB07C3;
    border-radius: 4px;
    height: 100%;
    padding: 0px clamp(14px, 1.7vw, 24px);

}

input {
    color: white;
    height: 100%;
    width: 100%;
    background: transparent;
    font-size: clamp(14px, 1.6vw, 18px);
}
</style>
